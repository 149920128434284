.typing-indicator {
    display: inline-flex;
    gap: 5px;
    margin-top: 5px;
    padding: 15px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.04);
  }
  
  .typing-indicator span {
    width: 8px;
    height: 8px;
    background-color: #333;
    border-radius: 50%;
    display: inline-block;
    animation: blink 1.4s infinite both;
  }
  
  .typing-indicator span:nth-child(1) {
    animation-delay: 0s;
  }
  
  .typing-indicator span:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .typing-indicator span:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes blink {
    0%, 80%, 100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }