@import "../scss/common/mixin";
@import "../scss/common/variables";
@import "../fonts/fonts.scss";

body {
  padding: 0;
  margin: 0;
  background: $innerbg;
}

// Typhography

h1 {
  @include heading1;
}

h2 {
  @include heading2;
}

h3 {
  @include heading3;
}

h4 {
  @include heading4;
}

h5 {
  @include heading5;
}

h6 {
  @include heading6;
}

p {
  @include paragraph;
}
.text-white {
  color: $text-white;
}
.text-overline {
  @include text-overline;
}
.text-overline2 {
  @include text-overline2;
}

// Buttons
button.fillbuttonwhite {
  @include fillbuttonwhite;
}

button.fillbutton {
  @include fillbutton;
}
button:disabled {
  // background: rgba(0, 0, 0, 1) !important;
  border: 2px solid rgba(0, 0, 0, 1) !important;
  color: rgba(255, 255, 255, 1) !important;
  background: $black !important;
  opacity: 40% !important;
}
// Form
input.input-text {
  @include input-text;
}

input.input-text2 {
  @include input-text2;
}

input.input-text-otp {
  @include input-text-otp;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.form-label {
  @include label2;
}
label {
  @include label;
}
span.dataLabel {
  @include dataLabel;
}
span.dataLabel2 {
  @include dataLabel2;
}
span.dataLabel3 {
  @include dataLabel3;
}
// misc

a.btnlink {
  text-decoration: none;
  button {
    text-decoration: none;
  }
}
a.btnlinkSecurity {
  width: 68%;
  text-decoration: none;
  button {
    text-decoration: none;
    width: 100% !important;
  }
}

// divider
hr {
  border-radius: 28px;
  height: 2px;
  background: $dividerbg;
  width: 100%;
  opacity: 10%;
}

// home page
.invitationpagebg {
  background: $black;
}
.mainpage {
  position: relative;
  height: 100%;
  padding: 0 30px;
  .content {
    position: relative;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    margin: auto;
    flex-direction: column;
    h1 {
      color: $green !important;
      @include heading1;
      font-size: 52.2px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      font-family: $worksans-bold;
      letter-spacing: -0.7px;
      margin-bottom: 15px;
    }
    figure {
      width: 371px;
      img {
        width: 371px;
      }
      margin-bottom: 100px;
    }
    @media (max-width: 767px) {
      figure {
        width: 300px;
        img {
          width: 300px;
        }

        margin-bottom: 100px;
      }
    }

    button.fillbutton {
      @include fillbutton;
      font-weight: 800;
      font-size: 17.6px;
      background: $orange;
      text-transform: uppercase;
      color: $text-black;
      font-family: $worksans-bold;
      letter-spacing: -0.7px;
      width: 365px;
      padding-top: 11px;
      line-height: normal;
      &:hover,
      &:focus {
        background: $orange !important;
      }
    }
    .for-host-sec{
      margin-top: 35px;
    }
  }
  &.cleaning_section {
    .content {
      figure {
        width: 200px;
        margin-bottom: 20px;
        img {
          width: 100%;
        }
      }
      .cleaning_address {
        margin-bottom: 25px;
      }
    }
  }
}

// welcome screen

.welcomePage {
  position: relative;
  // height: 100vh;
  padding: 0 30px;
  .content {
    position: relative;
    height: 91vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 414px;
    margin: auto;

    //    .spin {
    //       font-weight: 600;
    //       @include heading1;
    //   }

    //   .spin:after {
    //       content: "";
    //       -webkit-animation: spin 10s ease infinite; /* Safari */
    //       animation: spin 10s ease infinite;
    //   }
    #welcometext {
      position: relative;
      @include heading1;
    }

    #welcometext:after {
      content: "Welcome!";
      position: absolute;
      top: -42px;
      -webkit-animation: spin 8s ease infinite; /* Include -webkit- prefix */
      animation: spin 8s linear infinite;
    }

    @keyframes spin {
      0% {
        visibility: hidden;
      }
      9% {
        visibility: visible;
        content: "Welcome!";
      }
      18% {
        visibility: hidden;
      }
      27% {
        visibility: visible;
        content: "Let's go!";
      }
      36% {
        visibility: hidden;
      }
      45% {
        visibility: visible;
        content: "Can't Waitttt";
      }
      54% {
        visibility: hidden;
      }
      63% {
        visibility: visible;
        content: "Get Excited!!";
      }
      72% {
        visibility: hidden;
      }
      81% {
        visibility: visible;
        content: "YASSSSSS";
      }
      90% {
        visibility: hidden;
      }
      100% {
        visibility: visible;
        content: "Get packin'!";
      }
    }
  }

  .welcomebg {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-image: url(https://d2rweuuav95dhv.cloudfront.net/images/welcome-bg.gif);
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    // filter: blur(11px);
    // .overlay {
    //    background-color: rgba(0,0,0,0.2);
    //    position: absolute;
    //    height: 100vh;
    //    width: 100%;
    // }
    // video {
    //    position: absolute;
    //    height: 100vh;
    //    width: 100%;
    //    object-fit: cover;
    // }
  }
  // .react-datepicker-wrapper {
  //    width: 100%;
  //    input.input-text {
  //      @include input-text;
  //      width: 100%;
  //      &:focus-visible {
  //       outline: none;
  //       padding: .390rem 15px;
  //      }
  //    }
  //    button {
  //       &::after {
  //          background-color: $white;
  //          color: $black;
  //          border-radius: 50%;
  //          height: 16px;
  //          display: flex !important;
  //          align-items: center;
  //          justify-content: center;
  //          width: 16px;
  //          padding: 9px 10px 11px;
  //          text-align: center;
  //          content: "×";
  //       }
  //    }
  // }
  .contentFooter {
    position: absolute;
    bottom: 0;
    width: auto;
    text-align: center;
    left: 50%;
    transform: translate(-50%, -20px);
  }

  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    background: none;
    height: 100vh;
    width: 100%;
    backdrop-filter: blur(8px); /* Apply blur effect to the pseudo-element */
    -webkit-backdrop-filter: blur(8px);
    z-index: 0; /* Ensure the pseudo-element is behind the container */
  }

  .datepickerblock {
    label {
      color: $text-white;
      opacity: 1;
    }
    .calendardate {
      position: relative;
      input {
        border: 2px solid rgba(255, 255, 255, 0.1);
        background: rgba(255, 255, 255, 0.1) !important;
        color: $text-white;
        position: relative;
        padding-right: 35px;
        border-radius: 6px;
        box-shadow: none;
        &:focus,
        &:active {
          border: 2px solid rgba(255, 255, 255, 0.1);
          outline: none;
        }
        &::placeholder {
          color: $text-white;
        }
      }
      button.p-button-icon-only {
        background: none;
        border: none;
        position: absolute;
        right: 15px;
        top: 0px;
        padding-left: 0;
        padding-right: 0;
        width: auto;

        &:focus,
        &:active {
          border: none;
          outline: none;
        }
      }
      span.clearbtn {
        background: none;
        border: none;
        position: absolute;
        right: 15px;
        top: 12px;
        padding-left: 0;
        padding-right: 0;
        width: 16px;
        filter: invert(1);
        cursor: pointer;
        height: 16px;
        &:focus,
        &:active {
          border: none;
          outline: none;
          box-shadow: none;
        }
      }

      button.p-button-icon-only {
        background: none;
        border: none;
        box-shadow: none;
        &:focus,
        &:active {
          border: none;
          outline: none;
          box-shadow: none;
        }
      }
    }
  }

  .welcomebgvideo {
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0px;
    z-index: -1;
    video {
      max-width: 100%;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .welcomebgvideomobile {
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0px;
    z-index: -1;
    video {
      max-width: 100%;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
}
.p-datepicker:not(.p-datepicker-inline) {
  width: auto !important;
  left: 50% !important;
  transform: translate(-50%, 5px);

  @media (max-width: 1280px) {
    width: auto !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 767px) {
    width: 100% !important;
    top: 28% !important;
    left: inherit !important;
    transform: inherit;
  }
}

.p-datepicker {
  button.p-datepicker-prev,
  button.p-datepicker-next {
    display: block !important;
  }
}
.p-datepicker-calendar-container {
  .p-datepicker-calendar {
    th {
      text-align: center;
    }
  }
}

// inner pages

.checkedinpage {
  height: 100vh;
  padding: 0 10px 10px;
  // background: $innerbg;

  .card-section {
    width: 414px;
    margin: auto;

    .cardYellow {
      background: $yellow;
      border-radius: 18px;
      padding: 8px 8px 20px;
      margin-bottom: 5px;
      figure {
        width: 100%;
        // max-height: 221.66px;
        // min-height: 221.66px;
        // overflow: hidden;
        border-radius: 11px;
        margin-bottom: 0;
        img {
          max-width: 100%;
          // max-height: 221.66px;
          // min-height: 221.66px;
          border-radius: 11px;
          object-fit: fill;
        }
      }

      .content {
        display: flex;
        justify-content: space-between;
        padding: 15px 14px 5px;
        .checkedinDate {
          display: flex;
          flex-direction: column;
          position: relative;
          width: 50%;

          &:first-child {
            position: relative;
            width: 50%;
            padding-right: 30px;
            &::after {
              position: absolute;
              content: "";
              width: 1px;
              height: 24px;
              background: rgba(0, 0, 0, 0.08);
              right: 10px;
              top: 10px;
            }
          }
          &:last-child {
            padding-left: 30px;
          }
        }
      }
    }
    .cardOrange {
      background: $orange;
      border-radius: 18px;
      padding: 8px 8px 8px;
      .content {
        display: flex;
        flex-direction: column;
        padding: 5px 14px 5px;
        .checkedinDate {
          display: flex;
          flex-direction: column;

          label {
            @include label2;
          }
        }

        .react-tel-input {
          font-family: $worksans-regular;
          position: relative;
          input {
            @include input-text2;
            width: 100%;
            padding-left: 62px;
          }
          .flag-dropdown {
            background: transparent;
            border: none;

            .selected-flag {
              background: transparent;
            }

            &::after {
              position: absolute;
              width: 1px;
              height: 20px;
              background: rgba(0, 0, 0, 0.1);
              content: "";
              top: 15px;
              right: -12px;
            }
          }

          .country-list {
            width: 370px;
            border-radius: 12px;
          }
          .country-list::-webkit-scrollbar {
            background: #f2f2f2;
            block-size: 8px;
            border-end-end-radius: 14px;
            border-start-end-radius: 14px;
            inline-size: 4px;
            padding: 10px 0;
            position: absolute;
            right: 0;
          }

          .country-list::-webkit-scrollbar-track {
            background: transparent;
          }

          .country-list::-webkit-scrollbar-thumb {
            border-radius: 0.5rem;
            background: $innerbg;
          }

          .country-list::-webkit-scrollbar-corner {
            display: none;
          }
        }

        .react-international-phone-input-container{
          font-family: $worksans-regular;
          position: relative;
          input {
            @include input-text2;
            width: 100%;
            padding-left: 62px;
            padding: .375rem .75rem;
          }
          .react-international-phone-country-selector-button{
            border-radius: 3px 0 0 3px;
            bottom: 0;
            padding: 0;
            position: absolute;
            top: 0;
            height: 100%;
            border-radius: 12px 0px 0px 12px;
            background: transparent;
            border-color: transparent;
            padding-left: 3px;
            img{
              width: 17px;
            }
            &::after{
              background: #0000001a;
              content: "";
              height: 20px;
              position: absolute;
              right: -12px;
              top: 15px;
              width: 1px;
            }
          }
          .react-international-phone-input{
            font-family: $worksans-regular;
            font-size: 16px;
            font-weight: 400;
            height: 49px !important;
            width: 100%;
            height: 100%;
            border-radius: 12px;
            background: rgba(0, 0, 0, 0.04) !important;
            border: 2px solid #00000014;
            padding-left: 62px;
            &:active,&:focus{
              border: 2px solid #181818 !important;
              border-left: none;
            }
          }
          .react-international-phone-country-selector-dropdown{
            border-radius: 12px;
            width: 370px;
            .react-international-phone-country-selector-dropdown__list-item{
              padding: 7px 9px;
              .react-international-phone-country-selector-dropdown__list-item-country-name{
                font-size: 15px;
                font-family: $worksans-regular;
              }
            }
            &::-webkit-scrollbar {
              background: #f2f2f2;
              block-size: 8px;
              border-end-end-radius: 14px;
              border-start-end-radius: 14px;
              inline-size: 4px;
              padding: 10px 0;
              position: absolute;
              right: 0;
            }
  
            &::-webkit-scrollbar-track {
              background: transparent;
            }
  
            &::-webkit-scrollbar-thumb {
              border-radius: 0.5rem;
              background: $innerbg;
            }
  
            &::-webkit-scrollbar-corner {
              display: none;
            }
          }
        }

        p {
          margin: 15px 0 5px;
        }
        form {
          label {
            margin-bottom: 5px;
          }
        }
      }
      hr {
        margin: 15px 0 12px;
      }
    }
  }
}

.checkinginpage {
  height: 100vh;
  padding: 0 10px 10px;
  // background: $innerbg;

  .card-section {
    width: 414px;
    margin: auto;
    .accrodianBlock {
      .cardYellow {
        background: $yellow;
        border-radius: 18px;
        padding: 17px 0 15px;
        margin-bottom: 5px;
        border: none;
        p {
          font-size: 15px;
        }

        .accordion-header {
          button {
            background: transparent;
            box-shadow: none;
            padding-bottom: 0;
            padding-top: 0;
            &::after {
              display: none;
            }

            img {
              margin-right: 7px;
              margin-top: -2px;
            }
          }
          button.collapsed {
            background: transparent;
            box-shadow: none;
            padding-bottom: 0;
            padding-top: 0;
            display: flex;
            align-items: center;
            &::after {
              display: none;
            }

            img {
              margin-right: 7px;
              margin-top: 0px;
            }
          }
          h2 {
            margin-top: 0px;
            font-family: $worksans-medium;
            letter-spacing: -0.2px;
            display: flex;
            align-items: center;
            span {
              margin-left: 5px;
            }
          }
        }
        .accordion-body {
          padding-top: 15px;
          padding-bottom: 7px;
          .swiperSliderone {
            .swiper {
              height: 210px;
              .swiper-slide {
                .form-check {
                  position: relative;
                  .form-check-input {
                    width: 100%;
                    height: 180px;
                    background: none;
                    // border-radius: 14px;
                    box-shadow: none;
                    border: none;
                    margin-top: 0;
                    &:hover,
                    &:focus,
                    &:active {
                      box-shadow: none;
                      border: none;
                      background: none;
                    }

                    &:checked[type="checkbox"] {
                      background: none;
                      &:hover,
                      &:focus,
                      &:active {
                        box-shadow: none;
                        border: none;
                        background: none;
                      }
                    }
                  }

                  .form-check-input:checked[type="checkbox"] + label {
                    position: absolute;
                    top: 0;
                    font-size: $font-size-18;
                    font-family: $worksans-medium;
                    font-weight: 500;
                    color: $text-black;
                    left: 0;
                    width: 100%;
                    height: 180px;
                    border-radius: 14px;
                    background: rgba(255, 255, 255, 1);
                    padding: 65px 14px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    line-height: 21px;
                    letter-spacing: -0.2px;
                    opacity: 1;
                  }
                  .form-check-input[type="checkbox"] + label {
                    position: absolute;
                    top: 0;
                    font-size: $font-size-18;
                    font-family: $worksans-medium;
                    font-weight: 500;
                    left: 0;
                    width: 100%;
                    height: 180px;
                    border-radius: 14px;
                    background: rgba(30, 28, 34, 0.1);
                    padding: 65px 14px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    line-height: 21px;
                    opacity: 1;
                    letter-spacing: -0.2px;
                    opacity: 0.5;
                  }

                  label {
                    color: $text-label-checkbox;
                  }
                }
              }
              .swiper-slide.swiper-slide-active {
                .form-check {
                  position: relative;
                  .form-check-input {
                    width: 100%;
                    height: 180px;
                    background: none;
                    // border-radius: 14px;
                    box-shadow: none;
                    border: none;
                    margin-top: 0;
                    &:hover,
                    &:focus,
                    &:active {
                      box-shadow: none;
                      border: none;
                      background: none;
                    }

                    &:checked[type="checkbox"] {
                      background: none;
                      &:hover,
                      &:focus,
                      &:active {
                        box-shadow: none;
                        border: none;
                        background: none;
                      }
                    }
                  }

                  .form-check-input:checked[type="checkbox"] + label {
                    position: absolute;
                    top: 0;
                    font-size: $font-size-18;
                    font-family: $worksans-medium;
                    font-weight: 500;
                    color: $text-black;
                    left: 0;
                    width: 100%;
                    height: 180px;
                    border-radius: 14px;
                    background: rgba(255, 255, 255, 1);
                    padding: 65px 14px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    line-height: 21px;
                    letter-spacing: -0.2px;
                    opacity: 1;
                  }
                  .form-check-input[type="checkbox"] + label {
                    position: absolute;
                    top: 0;
                    font-size: $font-size-18;
                    font-family: $worksans-medium;
                    font-weight: 500;
                    left: 0;
                    width: 100%;
                    height: 180px;
                    border-radius: 14px;
                    background: rgba(30, 28, 34, 0.1);
                    padding: 65px 14px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    line-height: 21px;
                    color: $text-black;
                    letter-spacing: -0.2px;
                    opacity: 1;
                  }
                }
              }
              .swiper-pagination {
                bottom: 0;
                text-align: left;
                font-family: $worksans-semibold;
                font-weight: 500;
                font-size: $font-size-12;
                color: $text-black;
                opacity: 50%;
                letter-spacing: -1.3px;
              }
              .swiper-slide.autowidthchange {
                width: auto !important;

                .id_card_block.image-uploaded {
                  position: relative;
                  overflow: hidden;
                  display: inline-block;
                  width: auto;
                  height: 133px;
                  .id_card_uploaded {
                    width: auto;
                  }
                }
                .id_card_block_selfie.image-uploaded {
                  position: relative;
                  overflow: hidden;
                  display: inline-block;
                  width: auto;
                  height: 133px;
                }
                .id_card_uploaded {
                  width: auto;
                }
              }
            }
            &.houseRulesSwiperLink {
              .swiper {
                .swiper-slide {
                  .form-check {
                    .form-check-input[type=checkbox] + label {
                      padding: 50px 14px;
                      word-break: break-word;
                      &:has(a) { 
                        display: inline-block;
                        a{
                          word-break: break-word;
                          color: $text-black;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .swiperSliderone .swiper .swiper-slide-active {
            .id_card_uploaded {
              background: rgba(30, 28, 34, 0.1);
              border: none;
            }
          }

          button {
            div {
              text-transform: lowercase;
              &::first-letter {
                text-transform: uppercase;
              }
            }
          }
        }
      }
      .cardOrange {
        background: $orange;
        border-radius: 18px;
        padding: 17px 0 15px;
        margin-bottom: 5px;
        border: none;
        p {
          font-size: 15px;
        }

        .accordion-header {
          button {
            background: transparent;
            box-shadow: none;
            padding-bottom: 0;
            padding-top: 0;
            &::after {
              display: none;
            }

            img {
              margin-right: 7px;
              margin-top: -2px;
            }
          }
          button.collapsed {
            background: transparent;
            box-shadow: none;
            padding-bottom: 0;
            padding-top: 0;
            display: flex;
            align-items: center;
            &::after {
              display: none;
            }

            img {
              margin-right: 7px;
              margin-top: 0px;
            }
          }
          h2 {
            margin-top: 0px;
            font-family: $worksans-medium;
            letter-spacing: -0.2px;
            display: flex;
            align-items: center;
            span {
              margin-left: 5px;
            }
          }
        }
        .accordion-body {
          padding-top: 15px;
          padding-bottom: 7px;
          .readagreement {
            max-height: 233px;
            overflow-y: auto;
            margin-bottom: 20px;
            padding-right: 10px;
          }

          .readagreement::-webkit-scrollbar {
            background: #f2f2f2;
            block-size: 8px;
            border-end-end-radius: 14px;
            border-start-end-radius: 14px;
            inline-size: 4px;
            padding: 10px 0;
            position: absolute;
            right: 0;
          }

          .readagreement::-webkit-scrollbar-track {
            background: transparent;
          }

          .readagreement::-webkit-scrollbar-thumb {
            border-radius: 0.5rem;
            background: $innerbg;
          }

          .readagreement::-webkit-scrollbar-corner {
            display: none;
          }

          button {
            div {
              text-transform: lowercase;
              &::first-letter {
                text-transform: uppercase;
              }
            }
          }
        }
      }
      .cardBlue {
        background: $blue;
        border-radius: 18px;
        padding: 17px 0 15px;
        margin-bottom: 5px;
        border: none;

        .accordion-header {
          button {
            background: transparent;
            box-shadow: none;
            padding-bottom: 0;
            padding-top: 0;
            &::after {
              display: none;
            }

            img {
              margin-right: 7px;
              margin-top: -2px;
            }
          }
          button.collapsed {
            background: transparent;
            box-shadow: none;
            padding-bottom: 0;
            padding-top: 0;
            display: flex;
            align-items: center;
            &::after {
              display: none;
            }

            img {
              margin-right: 7px;
              margin-top: 0px;
            }
          }
          h2 {
            margin-top: 0px;
            font-family: $worksans-medium;
            letter-spacing: -0.2px;
            display: flex;
            align-items: center;
            span {
              margin-left: 5px;
            }
          }
        }
        .accordion-body {
          padding-top: 15px;
          padding-bottom: 7px;
          h5 {
            margin-bottom: 3px;
          }
          .sub-title {
            font-family: $worksans-regular;
            font-weight: 400;
            color: $text-black;
            font-size: $font-size-13;
            display: block;
            opacity: 60%;
            line-height: 15.76px;
          }
          .sub-title-2 {
            font-family: $worksans-regular;
            font-weight: 400;
            color: $text-black;
            font-size: $font-size-13;
            display: block;
            opacity: 70%;
            line-height: 15.76px;
          }

          .swiperSliderone {
            .swiper {
              height: 160px;

              // .id_screen_upd {
              //    position: relative;
              //    overflow: hidden;
              //    display: inline-block;
              //    width: 100%;
              //    height: 133px;
              //  }

              //  .btn {
              //    border: 1px dashed rgba(0, 0, 0, 0.3);
              //    background-color: none;
              //    padding: 8px 20px;
              //    border-radius: 10px;
              //    font-size: 20px;
              //    font-weight: bold;
              //    width: 100%;
              //    height: 133px;
              //    p {
              //       @include paragraph;
              //       text-transform: lowercase;
              //          &::first-letter {
              //             text-transform: uppercase;
              //          }

              //       span {
              //          text-transform: uppercase;
              //       }
              //    }
              //  }

              //  .id_screen_upd input[type=file] {
              //    font-size: 100px;
              //    position: absolute;
              //    left: 0;
              //    top: 0;
              //    opacity: 0;
              //    width: 100%;
              //  }

              //  .swiper-slide.swiper-slide-active {

              //    .btn {
              //       border: none;
              //       background-color: rgba(30, 28, 34, 0.1);
              //       padding: 8px 20px;
              //       border-radius: 10px;
              //       font-size: 20px;
              //       font-weight: bold;
              //       width: 100%;
              //       height: 133px;
              //       p {
              //          @include paragraph;
              //          text-transform: lowercase;
              //             &::first-letter {
              //                text-transform: uppercase;
              //             }

              //          span {
              //             text-transform: uppercase;
              //          }
              //       }
              //     }

              //  }

              .id_card_block {
                position: relative;
                overflow: hidden;
                display: inline-block;
                width: 100%;
                height: 133px;
              }

              .id_card_uploaded {
                border: 1px dashed rgba(0, 0, 0, 0.3);
                background: none;
                padding: 0;
                border-radius: 10px;
                font-size: 20px;
                font-weight: bold;
                width: 100%;
                height: 133px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                overflow: hidden;
                figure {
                  height: 133px;
                  width: 100%;
                  margin: 0;
                  position: relative;

                  img {
                    height: 133px;
                    width: 100%;
                    object-fit: contain;
                  }

                  span.deletePreview {
                    position: absolute;
                    top: 1px;
                    right: 5px;
                    padding: 0;
                    background: none;
                    cursor: pointer;
                    img {
                      width: 28px;
                      height: 28px;
                    }
                  }
                }
              }

              .id_card_block_selfie {
                position: relative;
                overflow: hidden;
                display: inline-block;
                width: 100px;
                height: 133px;
              }

              .id_card_uploaded_selfie {
                border: 1px dashed rgba(0, 0, 0, 0.3);
                background: none;
                padding: 0;
                border-radius: 10px;
                font-size: 20px;
                font-weight: bold;
                width: 100px;
                height: 133px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                overflow: hidden;
                figure {
                  height: 133px;
                  width: 100%;
                  margin: 0;
                  position: relative;

                  img {
                    height: 133px;
                    width: 100%;
                    object-fit: contain;
                  }

                  span.deletePreview {
                    position: absolute;
                    top: 1px;
                    right: 5px;
                    padding: 0;
                    background: none;
                    cursor: pointer;

                    img {
                      width: 28px;
                      height: 28px;
                    }
                  }
                }
              }

              .id_card {
                border: 1px dashed rgba(0, 0, 0, 0.3);
                background: none;
                padding: 8px 20px;
                border-radius: 10px;
                font-size: 20px;
                font-weight: bold;
                // width: 100px;
                height: 133px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                img {
                  width: 32px;
                }

                p.id_label {
                  font-size: $font-size-16 !important;
                  color: $text-para-2 !important;
                  margin-top: 5px;
                  @include paragraph;

                  text-transform: lowercase;
                  &::first-letter {
                    text-transform: uppercase;
                  }

                  span {
                    text-transform: uppercase;
                  }
                }
              }

              .id_card_selfie {
                border: 1px dashed rgba(0, 0, 0, 0.3);
                background: none;
                padding: 15px 20px 0;
                border-radius: 10px;
                font-size: 20px;
                font-weight: bold;
                width: 100px;
                height: 133px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                img {
                  width: 32px;
                }

                p.id_label {
                  font-size: $font-size-16 !important;
                  color: $text-para-2 !important;
                  margin-top: 5px;
                  @include paragraph;

                  text-transform: lowercase;
                  &::first-letter {
                    text-transform: uppercase;
                  }

                  span {
                    text-transform: uppercase;
                  }
                }
              }

              .swiper-slide.swiper-slide-active {
                .id_card {
                  border: none;
                  background-color: rgba(30, 28, 34, 0.1);
                  padding: 8px 20px;
                  border-radius: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  // width: 100px;
                  height: 133px;
                  img {
                    width: 32px;
                  }
                  p {
                    @include paragraph;
                    text-transform: lowercase;
                    &::first-letter {
                      text-transform: uppercase;
                    }

                    span {
                      text-transform: uppercase;
                    }
                  }
                }

                .id_card_selfie {
                  border: none;
                  background-color: rgba(30, 28, 34, 0.1);
                  padding: 15px 20px 0;
                  border-radius: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  width: 100px;
                  height: 133px;
                  img {
                    width: 32px;
                  }
                  p {
                    @include paragraph;
                    text-transform: lowercase;
                    &::first-letter {
                      text-transform: uppercase;
                    }

                    span {
                      text-transform: uppercase;
                    }
                  }
                }
              }

              .swiper-pagination {
                bottom: 0;
                text-align: left;
                font-family: $worksans-semibold;
                font-weight: 500;
                font-size: $font-size-12;
                color: $text-black;
                opacity: 50%;
                letter-spacing: -1.3px;
              }
              .swiper-slide.autowidthchange {
                width: auto !important;

                .id_card_block.image-uploaded {
                  position: relative;
                  overflow: hidden;
                  display: inline-block;
                  width: auto;
                  height: 133px;
                  .id_card_uploaded {
                    width: auto;
                    figure {
                      width: auto;
                      img {
                        width: auto;
                      }
                    }
                  }
                }
                .id_card_block_selfie.image-uploaded {
                  position: relative;
                  overflow: hidden;
                  display: inline-block;
                  width: auto;
                  height: 133px;
                  .id_card_uploaded {
                    width: auto;
                    figure {
                      width: auto;
                      img {
                        width: auto;
                      }
                    }
                  }
                }
              }
            }
          }
          .swiperSliderone .swiper .swiper-slide-active {
            .id_card_uploaded {
              background: rgba(30, 28, 34, 0.1);
              border: none;
            }
          }

          button {
            div {
              text-transform: lowercase;
              &::first-letter {
                text-transform: uppercase;
              }
            }
          }
        }
      }
      .cardGreen {
        background: $green;
        border-radius: 18px;
        padding: 17px 0 15px;
        margin-bottom: 5px;
        border: none;

        .accordion-header {
          button {
            background: transparent;
            box-shadow: none;
            padding-bottom: 0;
            padding-top: 0;
            &::after {
              display: none;
            }

            img {
              margin-right: 7px;
              margin-top: -2px;
            }
          }
          button.collapsed {
            background: transparent;
            box-shadow: none;
            padding-bottom: 0;
            padding-top: 0;
            display: flex;
            align-items: center;
            &::after {
              display: none;
            }

            img {
              margin-right: 7px;
              margin-top: 0px;
            }
          }
          h2 {
            margin-top: 0px;
            font-family: $worksans-medium;
            letter-spacing: -0.2px;
            display: flex;
            align-items: center;
            span {
              margin-left: 5px;
            }
          }
        }
        .accordion-body {
          padding-top: 15px;
          padding-bottom: 7px;
          .radiobox {
            .form-check-input:checked[type="radio"] {
              border: 6px solid $black;
              background: none;
            }
            .form-check-input[type="radio"] {
              border: 1px solid rgba(0, 0, 0, 0.3);
              background: none;

              &:focus,
              &:hover,
              &:active {
                box-shadow: 0 0 0 0.25rem rgb(93 93 93 / 25%) !important;
              }
            }
            label {
              font-family: $worksans-medium;
              font-size: $font-size-16;
              color: $text-black;
              font-weight: 500;
              opacity: 1;
              .swipe-html-text{
                p{
                  font-family: $worksans-medium;
                  font-size: $font-size-16;
                  color: $text-black;
                  font-weight: 500;
                  opacity: 1;
                  word-break: break-word;
                  margin-bottom: 0;
                  span{
                    color: $text-black !important;
                  }
                }
              }
            }
          }

          button {
            div {
              text-transform: lowercase;
              &::first-letter {
                text-transform: uppercase;
              }
            }
          }


          ul, .review-protection-description {
            padding-left: 15px;
            margin-bottom: 60px;
            li, p {
              @include paragraph2;
              margin-bottom: 7px;
              opacity: 70% !important;
              &::marker {
                position: absolute;
                font-size: 95%;
              }
            }
          }
        }
        .review-protection-description{
          padding-left: 0 !important;
          display: block;
        }
        &.customcardPink{
          background: #FF8DE8;
          .reservationnpage {
            padding: 0;
            .card-section {
              width: 100%;
              .cardGreen2{
                padding: 8px 0 20px;
              }
            }
          }
        }
        &.customCardBlue{
          background: $blue;
          p{
            font-weight: 600;
          }
        }
      }

      .accrodionCollapsed {
        pointer-events: none;
        button.collapsed {
          background: transparent;
          box-shadow: none;
          padding-bottom: 0;
          padding-top: 0;
          display: flex;
          align-items: center;
          &::after {
            display: none;
          }

          img {
            margin-right: 7px !important;
            margin-top: 0px !important;
            width: 17px;
          }
        }
      }
    }
  }
}

.reservationnpage {
  height: 100%;
  padding: 0 10px;
  // background: $innerbg;

  .card-section {
    width: 414px;
    margin: auto;
    .cardGreen {
      background: $green;
      border-radius: 18px;
      padding: 8px 8px 20px;
      margin-bottom: 5px;
      .content {
        label {
          font-size: 15px;
        }
      }

      .reserved {
        position: relative;
        .reservedDate {
          position: absolute;
          display: flex;
          justify-content: space-between;
          padding: 15px 14px 5px;
          width: 100%;
          z-index: 1;
          overflow: hidden;
          .checkedinDate {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 50%;
            label,
            .dataLabel {
              color: rgba(255, 255, 255, 1);
            }
            &:first-child {
              position: relative;
              width: 50%;
              padding-right: 30px;
              &::after {
                position: absolute;
                content: "";
                width: 1px;
                height: 24px;
                background: rgba(255, 255, 255, 0.08);
                right: 10px;
                top: 10px;
              }
            }
            &:last-child {
              padding-left: 30px;
            }
          }
        }
        figure {
          width: 100%;
          // max-height: 150px;
          // min-height: 150px;
          // max-height: 221.66px;
          // min-height: 221.66px;
          // overflow: hidden;
          border-radius: 11px;
          margin-bottom: 0;
          position: relative;
          overflow: hidden;
          img {
            max-width: 100%;
            // max-height: 150px;
            // min-height: 150px;
            // max-height: 221.66px;
            // min-height: 221.66px;
            border-radius: 11px;
            object-fit: cover;
          }

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(180deg, #00000099, transparent);
          }
        }
      }
      .content {
        display: flex;
        justify-content: space-between;
        padding: 15px 14px 5px;
        align-items: center;
        .checkedinDate {
          display: flex;
          flex-direction: column;
          position: relative;
          // width: 50%;
          width: 50px;
          text-align: right;
          label {
            line-height: 16.42px;
            color: $text-black;
            opacity: 80%;
          }
          img {
            width: 38px;
          }
          &:first-child {
            position: relative;
            // width: 50%;
            width: calc(100% - 50px);
            padding-right: 0px;
            text-align: left;
            &::after {
              position: absolute;
              content: "";
              width: 1px;
              height: 24px;
              background: rgba(0, 0, 0, 0.08);
              right: 10px;
              top: 10px;
              display: none;
            }
          }
          &:last-child {
            padding-left: 10px;
          }
        }
      }
    }
    .cardBlue {
      background: $blue;
      border-radius: 18px;
      padding: 8px 20px 20px;
      margin-bottom: 5px;
      h2 {
        margin: 10px 0;
        font-family: $worksans-medium;
      }
      figure {
        width: 100%;
        // max-height: 221.66px;
        // min-height: 221.66px;
        // overflow: hidden;
        border-radius: 11px;
        margin-bottom: 20px;
        img {
          max-width: 100%;
          // max-height: 221.66px;
          // min-height: 221.66px;
          border-radius: 11px;
          object-fit: fill;
        }
      }
      p {
        color: $text-black;
        opacity: 80%;
        line-height: 16.42px;
      }
      .content {
        display: flex;
        justify-content: space-between;
        padding: 5px 0px 5px;
        width: 100%;
        .checkedinDate {
          display: flex;
          flex-direction: column;
          position: relative;
          width: 50%;

          &:first-child {
            position: relative;
            width: 50%;
            padding-right: 30px;
            &::after {
              position: absolute;
              content: "";
              width: 1px;
              height: 24px;
              background: rgba(0, 0, 0, 0.08);
              right: 10px;
              top: 10px;
            }
          }
          &:last-child {
            padding-left: 30px;
          }
          span.dataLabel {
            white-space: break-spaces;
            word-wrap: break-word;
          }
        }
      }
      // video {
      //    width: 100%;
      //    height: 533px;
      //    border: none;
      // }

      video {
        height: auto;
        width: 100%;
        // aspect-ratio: 9 / 16;
        // aspect-ratio: 1.17 / 1;
        border: 0;
        border-radius: 11px;
      }

      // iframe {
      //    height: auto;
      //    width: 100%;
      //    // aspect-ratio: 9 / 16;
      //    aspect-ratio: 1.17 / 1;
      //    border: 0;
      //    border-radius: 11px;
      // }
    }
    .cardYellow {
      background: $yellow;
      border-radius: 18px;
      padding: 8px 20px 20px;
      margin-bottom: 5px;
      h2 {
        margin: 10px 0;
        font-family: $worksans-medium;
      }
      p {
        color: $text-black;
        opacity: 80%;
        line-height: 16.42px;
        font-size: 15px;
      }
      button {
        text-transform: lowercase;
        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
    .cardGreen2 {
      background: $green;
      border-radius: 18px;
      padding: 8px 20px 20px;
      margin-bottom: 5px;
      h2 {
        margin: 10px 0 13px;
        font-family: $worksans-medium;
      }
      .localMap {
        background: $white;
        padding: 10px;
        border-radius: 18px;

        figure {
          width: 100%;
          max-height: 145px;
          min-height: 145px;
          overflow: hidden;
          border-radius: 6px;
          margin-bottom: 0;
          img {
            width: 100%;
            max-height: 145px;
            min-height: 145px;
            border-radius: 6px;
            object-fit: cover;
          }
        }
        .content {
          display: flex;
          justify-content: space-between;
          padding: 15px 0px 5px;
          align-items: center;
          flex-direction: column;
          .dataLabel {
            font-weight: 700;
            font-family: 'WorkSans-SemiBold';
          }
          .card-subtitle {
            font-size: $font-size-12;
            color: $text-para;
            font-family: $worksans-regular;
            display: block;
            width: 100%;
          }
          button {
            div {
              text-transform: lowercase;
              &::first-letter {
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }
    .cardPink{
      background: $pink;
      .pink-slider{
        .swiper{
          .swiper-wrapper{
            .swiper-slide{
              .localMap{
                figure{
                  max-height: 154px;
                  min-height: 154px;
                  img{
                    max-height:154px;
                    min-height: 154px;
                  }
                }
                .content{
                  .dataLabel{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }
            }
          }
        }
      }
    }
    .cardOrange {
      background: $orange;
      border-radius: 18px;
      padding: 8px 20px 20px;
      margin-bottom: 5px;
      h2 {
        margin: 10px 0;
        font-family: $worksans-medium;
      }

      button {
        text-transform: lowercase;
        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
    .cardBlank {
      background: none;
      padding: 20px 15px 40px;
      margin-bottom: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h2 {
        margin: 10px 0 25px;
        color: $text-white;
        font-family: $worksans-medium;
        text-align: center;
      }
      figure {
        background: #fff;
        border: 2px solid #fff;
        border-radius: 50px;
        margin-bottom: 5px;
        max-height: 60px;
        min-height: 60px;
        overflow: hidden;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 100%;
          object-fit: fill;
          padding: 0;
          width: 100%;
        }
      }

      button.outlinebutton {
        border: 2px solid $white !important;
        color: $text-white !important;
        @include outlinebutton;
        &:hover,
        &:focus,
        &:active {
          border: 2px solid $white !important;
          color: $text-white !important;
        }
      }
    }
    .chat-ai-icon{
      position: fixed;
      bottom: 6px;
      margin-left: 0px;
      z-index: 9;
      border-radius: 50% 50% 50% 0;
      background: #25211E;
      cursor: pointer;
      .chat-ai-img{
        border-radius: 100%;
        width: 61px;
        height: 60px;
        background: #2e1c65;
        outline: 1px solid #2e1c65;
        img{
          height: 100%;
          width: 100%;
          border-radius: 100%;
          object-fit: cover;
        }
      }
      &::after{
        content: "";
        position: absolute;
        width: 576px;
        height: 8px;
        left: 0;
        bottom: -6px;
        background: #25211e;
        z-index: -1;
      }
    }
  }
}

// Modalbox

body.modal-open {
  padding-right: 0 !important;
  overflow: hidden !important;
  @media (max-width: 767px) {
    padding-right: 0 !important;
    overflow: hidden !important;
  }
}
body.signOndotted {
  overflow: auto !important;
}
.modal {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.modal-open {
  padding-right: 0 !important;
  overflow: hidden !important;
  @media (max-width: 767px) {
    padding-right: 0 !important;
    overflow: hidden !important;
  }
  .modal-backdrop.show {
    opacity: 0;
  }
}

.modal.addcreditcard {
  @include modalbox;

  .modal-dialog {
    top: 22%;
    width: 21%;
    max-width: 21%;

    @media (max-width: 1280px) {
      top: 1%;
      width: 30%;
      max-width: 30%;
    }

    @media (max-width: 1024px) {
      top: 30%;
      width: 47%;
      max-width: 47%;
    }
    @media (max-width: 991px) {
      top: 30%;
      width: 47%;
      max-width: 47%;
    }
    @media (max-width: 767px) {
    }

    .stripeImg {
      max-width: 351px;

      @media (max-width: 1024px) {
        max-width: 351px;
      }
      @media (max-width: 991px) {
        max-width: 351px;
      }
      @media (max-width: 767px) {
        max-width: 100%;
      }
    }
  }
  .modal-header {
    button {
      background-image: var(--bs-btn-close-bg) !important;
    }

    @media (max-width: 1024px) {
      text-align: right !important;
      position: absolute !important;
      padding: 18px 18px 0 !important;
      button {
        background-image: var(--bs-btn-close-bg) !important;
      }
    }
    @media (max-width: 991px) {
      text-align: right !important;
      position: absolute !important;
      padding: 18px 18px 0 !important;
      button {
        background-image: var(--bs-btn-close-bg) !important;
      }
    }
    @media (max-width: 767px) {
      text-align: center !important;
      position: relative !important;
      padding: 0px 13px 0 !important;
      button {
        background-image: url(../../assets/images/close-btn.svg) !important;
      }
    }
  }
  .modal-body {
    h2 {
      padding-top: 10px !important;
      text-align: left !important;
      @media (max-width: 1024px) {
        text-align: left !important;
        padding-top: 15px !important;
      }
      @media (max-width: 991px) {
        text-align: left !important;
        padding-top: 15px !important;
      }
      @media (max-width: 767px) {
        text-align: left !important;
        padding-top: 15px !important;
      }
    }
  }
}

.modal.earlycheckin {
  @include modalbox;

  .modal-dialog {
    top: 20%;
    // width: 21%;
    // max-width: 21%;
    .modal-content {
      background: $yellow;

      .range {
        input {
          background: none !important;
          border: none;
          height: auto !important;
          &:focus,
          &:active {
            border: none !important;
            box-shadow: none !important;
            outline: none !important;
          }
        }
        input[type="range"]::-webkit-slider-thumb {
          background: $black !important;
          margin-top: -5px;
          border: none;
        }

        /* All the same stuff for Firefox */
        input[type="range"]::-moz-range-thumb {
          background: $black !important;
          margin-top: -5px;
          border: none;
        }

        /* All the same stuff for IE */
        input[type="range"]::-ms-thumb {
          background: $black !important;
          margin-top: -5px;
          border: none;
        }
        input[type="range"]::-webkit-slider-runnable-track {
          background: #e5d642; /* Change this to the color you want */
          height: 5px;
        }

        input[type="range"]::-moz-range-track {
          background: #e5d642; /* Change this to the color you want */
          height: 5px;
        }

        input[type="range"]::-ms-track {
          background: #e5d642; /* Change this to the color you want */
          height: 5px;
        }
        .input-text2-modal {
          background: #fff !important;
          border: 2px solid #00000014;
          border-radius: 12px;
          color: #000;
          font-family: Work Sans;
          font-size: 16px;
          font-weight: 400;
          height: 49px !important;
          padding-top: 6px;
          opacity: 0.8;
          cursor: no-drop;
          &:focus-visible {
            outline: none;
            box-shadow: none;
          }
          &:active {
            border: 2px solid #00000014 !important;
          }
        }
      }
      img.payment {
        max-width: 100%;
        border-radius: 11px;
        margin: 5px 0 15px;
        padding: 10px 5px;
        background: #fff;
        opacity: 50%;
      }
    }
    @media (max-width: 991px) {
      width: 54%;
      max-width: 54%;
    }

    @media (max-width: 820px) {
      width: 50%;
      max-width: 50%;
    }
    @media (max-wdith: 767px) {
      width: 42%;
      max-width: 42%;
    }
  }
  .modal-header {
    button {
      background-image: var(--bs-btn-close-bg) !important;
    }

    @media (max-width: 1024px) {
      button {
        background-image: url(../../assets/images/close-btn.svg) !important;
      }
    }
    @media (max-width: 991px) {
      button {
        background-image: url(../../assets/images/close-btn.svg) !important;
      }
    }
    @media (max-width: 767px) {
      button {
        background-image: url(../../assets/images/close-btn.svg) !important;
      }
    }
  }
}

.modal.idcard {
  @include modalbox;

  button.outlinebutton {
    border: 2px solid $black !important;
  }

  .id_screen_upd {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 100%;
    // height: 133px;
  }

  .btn {
    border: 1px dashed rgba(0, 0, 0, 0.3);
    background-color: none;
    padding: 8px 20px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    height: 133px;
    p {
      @include paragraph;
      text-transform: lowercase;
      &::first-letter {
        text-transform: uppercase;
      }

      span {
        text-transform: uppercase;
      }
    }
  }

  .id_screen_upd input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
  }
  .id_screen_upd input.input_file_upload {
    width: 0;
  }

  .modal-header {
    button {
      background-image: var(--bs-btn-close-bg) !important;
    }

    @media (max-width: 1024px) {
      padding: 18px 18px 0 !important;
      text-align: right !important;
      position: absolute !important;

      button {
        background-image: var(--bs-btn-close-bg) !important;
      }
    }
    @media (max-width: 991px) {
      padding: 18px 18px 0 !important;
      text-align: right !important;
      position: absolute !important;

      button {
        background-image: var(--bs-btn-close-bg) !important;
      }
    }
    @media (max-width: 767px) {
      padding: 0px 13px 0 !important;
      text-align: center !important;
      position: relative !important;

      button {
        background-image: url(../../assets/images/close-btn.svg) !important;
      }
    }
  }
  .modal-body {
    h2 {
      padding-top: 10px !important;
      text-align: left !important;
      @media (max-width: 1024px) {
        text-align: left !important;
        padding-top: 15px !important;
      }
      @media (max-width: 991px) {
        text-align: left !important;
        padding-top: 15px !important;
      }
      @media (max-width: 767px) {
        text-align: left !important;
        padding-top: 15px !important;
      }
    }
  }
}
.modal.eccomerceItem {
  .modal-dialog {
    .modal-content {
      background: $pink;
    }
  }
  .item_pur_title{
   display: flex;
   justify-content: space-between;
   align-items: baseline;
   margin-top: 10px;
 }
 .modal-header{
  button{
    background-image: var(--bs-btn-close-bg) !important;
  }
 }
 .modal-body{
  h2{
    padding-right: 15px !important;
  }
  .item_pur_title{
    .upsell-modal-title{
      display: flex;
      justify-content: end;
      min-width: 161px;
      max-width: 161px;
      padding-right: 0 !important;
    }
  }
 }
 @media (max-width:767px){
  .modal-dialog{
    .modal-content{
      .modal-header{
        text-align: end;
      }
    }
  }
 }
}
.modal.agreement {
  .modal-header {
    padding: 13px 13px 0 !important;
    border: none;
    display: block;
    width: 100%;
    text-align: right !important;
    position: absolute !important;
    z-index: 1;

    @media (max-width: 1024px) {
      text-align: right !important;
      padding: 18px 18px 0 !important;
      position: absolute !important;
    }
    @media (max-width: 991px) {
      text-align: right !important;
      padding: 18px 18px 0 !important;
      position: absolute !important;
    }
    @media (max-width: 767px) {
      text-align: center !important;
      position: relative !important;
      padding: 0px 13px 0 !important;
    }

    button {
      background-image: var(--bs-btn-close-bg);
    }

    @media (max-width: 1024px) {
      button {
        background-image: var(--bs-btn-close-bg);
      }
    }
    @media (max-width: 991px) {
      button {
        background-image: var(--bs-btn-close-bg);
      }
    }
    @media (max-width: 767px) {
      button {
        background-image: url(../../assets/images/close-btn.svg);
      }
    }
  }
  .modal-body {
    padding-bottom: 30px;

    h2 {
      padding-top: 10px !important;
      text-align: left !important;
      @media (max-width: 1024px) {
        text-align: left !important;
        padding-top: 15px !important;
      }
      @media (max-width: 991px) {
        text-align: left !important;
        padding-top: 15px !important;
      }
      @media (max-width: 767px) {
        text-align: left !important;
        padding-top: 15px !important;
      }
    }
    .custom-checkbox {
      cursor: pointer;
      user-select: none;
      opacity: 1;
    
      input {
        display: none;
      }
    
      .checkmark {
        width: 22px;
        height: 22px;
        border: 2px solid #FFC062;
        border-radius: 4px;
        background-color: #fff;
        display: inline-block;
        position: relative;
        margin-right: 8px;
        transition: all 0.3s ease;
        margin-bottom: -8px;
    
        &::after {
          content: '';
          position: absolute;
          top: 0px;
          left: 5px;
          width: 7px;
          height: 14px;
          border: solid #fff;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          opacity: 0;
          transition: opacity 0.2s;
        }
      }
    
      input:checked + .checkmark {
        background-color: #FFC062;
        border-color: #FFC062;
    
        &::after {
          opacity: 1;
        }
      }
    
      .label-text {
        margin-left: 4px;
      }
    }
    .checkBox-text{
      span.checkBox-text-heading{
        font-size: 14px !important;
        opacity: 70% !important;
        font-family: $worksans-regular !important;
        line-height: normal;
        display: inline-block;
        line-height: 1.2;
        margin-bottom: 0;
        color: $black !important;
        a {
          color: $black !important;
        }
        p {
          display: inline;
        }
      }
    }
  }
  .moreContent {
    max-height: 300px;
    overflow-y: auto;
    padding-right: 10px;
  }

  @include modalbox;
}

.smsCheckbox{
  .custom-checkbox {
    cursor: pointer;
    user-select: none;
    opacity: 1;
  
    input {
      display: none;
    }
  
    .checkmark {
      width: 22px;
      height: 22px;
      border: 2px solid #00000014;
      border-radius: 4px;
      background-color: #fff;
      display: inline-block;
      position: relative;
      margin-right: 8px;
      transition: all 0.3s ease;
      margin-bottom: -8px;
  
      &::after {
        content: '';
        position: absolute;
        top: 0px;
        left: 5px;
        width: 7px;
        height: 14px;
        border: solid #FFC062;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        opacity: 0;
        transition: opacity 0.2s;
      }
    }
  
    input:checked + .checkmark {
      background-color: #fff;
      border-color: #00000014;
  
      &::after {
        opacity: 1;
      }
    }
  
    .label-text {
      margin-left: 4px;
    }
  }
  .checkBox-text{
    span.checkBox-text-heading{
      font-size: 13px !important;
      opacity: 70% !important;
      font-family: $worksans-regular !important;
      line-height: normal;
      display: inline-block;
      line-height: 1.2;
      margin-bottom: 0;
      color: $black !important;
      a {
        color: $black !important;
      }
      p {
        font-size: 13px;
        display: inline;
        opacity: 85%;
      }
    }
    @media (max-width: 767px) {
      span.checkBox-text-heading{
        font-size: 12px !important;
        p{
          font-size: 12px;
        }
      }
    }
    @media (max-width: 390px) {
      span.checkBox-text-heading{
        font-size: 10px !important;
        p{
          font-size: 10px;
          line-height: 1.4;
        }
      }
    }
  }
}

.modal.consumer-content-modal {
  .modal-dialog {
    .modal-content{
      .modal-header{
        padding: 10px 15px !important;
        display: flex !important;
        position: static !important;
        text-align: left !important;
        .modal-title{
          font-size: 20px !important;
          font-family: $worksans-medium !important;
          color: $black !important;
          opacity: 1 !important;
          margin: 0 !important;
          font-weight: 600;
        }
      }
    }
  }
  @include modalbox;
}

.modal.terms-privacy {
  .modal-header {
    padding: 13px 13px 0 !important;
    border: none;
    display: block;
    width: 100%;
    text-align: right !important;
    position: relative !important;
    z-index: 1;

    @media (max-width: 1024px) {
      text-align: right !important;
      position: absolute !important;
      padding: 18px 18px 0 !important;
    }
    @media (max-width: 991px) {
      text-align: right !important;
      position: absolute !important;
    }
    @media (max-width: 767px) {
      text-align: center !important;
      position: relative !important;
      padding: 0px 13px 0 !important;
    }

    button {
      background-image: var(--bs-btn-close-bg) !important;
    }

    @media (max-width: 767px) {
      button {
        background-image: url(../../assets/images/close-btn.svg) !important;
      }
    }
  }
  .modal-body h2 {
    padding-top: 15px !important;
  }

  .moreContent {
    max-height: 300px;
    overflow-y: auto;
    padding-right: 10px;

    @include paragraph2;

    p {
      @include paragraph2;
    }
  }

  @include modalbox;
}

.modal.terms-privacy-policy {
  .modal-header {
    padding: 13px 13px 0 !important;
    border: none;
    display: block;
    width: 100%;
    text-align: right !important;
    position: absolute !important;
    z-index: 1;

    @media (max-width: 1024px) {
      text-align: right !important;
      width: auto;
      padding: 3px 5px 0 !important;
    }
    @media (max-width: 991px) {
      text-align: right !important;
      width: auto;
      padding: 3px 5px 0 !important;
    }
    @media (max-width: 767px) {
      text-align: right !important;
      width: auto;
      padding: 3px 5px 0 !important;
    }

    button {
      background-image: var(--bs-btn-close-bg) !important;
    }

    @media (max-width: 1024px) {
      button {
        background-image: url(../../assets/images/close-btn.svg);
        position: relative;
        top: 15px;
        right: 15px;
      }
    }
    @media (max-width: 991px) {
      button {
        background-image: url(../../assets/images/close-btn.svg);
        position: relative;
        top: 15px;
        right: 15px;
      }
    }
    @media (max-width: 767px) {
      button {
        background-image: url(../../assets/images/close-btn.svg);
        position: relative;
        top: 15px;
        right: 15px;
      }
    }
  }
  .modal-body {
    @media (max-width: 1024px) {
      padding-top: 0px !important;
    }

    @media (max-width: 991px) {
      padding-top: 0px !important;
    }

    @media (max-width: 767px) {
      padding-top: 20px !important;
    }
    h2 {
      padding-top: 10px !important;
    }
  }

  .moreContent {
    max-height: 300px;
    overflow-y: auto;
    padding-right: 10px;

    @include paragraph2;

    p,
    table th,
    table td,
    u,
    b,
    i {
      @include paragraph2;
    }
    ul li,
    ol li {
      @include paragraph2;
      ul li,
      ol li {
        @include paragraph2;
        opacity: inherit;

        ul li,
        ol li {
          @include paragraph2;
          opacity: inherit;

          ul li,
          ol li {
            @include paragraph2;
            opacity: inherit;

            ul li,
            ol li {
              @include paragraph2;
              opacity: inherit;

              ul li,
              ol li {
                @include paragraph2;
                opacity: inherit;
              }
            }
          }
        }
      }
    }
  }

  @include modalbox;
}

.otp-modal {
  .modal-header {
    padding: 13px 13px 0 !important;
    border: none;
    display: block;
    width: 100%;
    text-align: right !important;
    position: absolute !important;
    z-index: 1;

    @media (max-width: 1024px) {
      text-align: right !important;
      position: absolute !important;
      padding: 18px 18px 0 !important;
    }
    @media (max-width: 991px) {
      text-align: right !important;
      position: absolute !important;
    }
    @media (max-width: 767px) {
      text-align: center !important;
      position: relative !important;
      padding: 0px 13px 0 !important;
    }

    button {
      background-image: var(--bs-btn-close-bg) !important;
    }

    @media (max-width: 767px) {
      button {
        background-image: url(../../assets/images/close-btn.svg) !important;
      }
    }
  }
  .modal-body h2 {
    padding-top: 10px !important;
  }

  .moreContent {
    max-height: 300px;
    overflow-y: auto;
    padding-right: 10px;

    @include paragraph2;

    p {
      @include paragraph2;
    }
  }

  @include modalbox;
}

.agreement-d-none {
  display: none !important;
}

.agreement-d-block {
  display: block;
}

.modal.requestinvitation {
  .modal-dialog {
    top: 11.9%;
    width: 680px;
    max-width: 680px;

    @media (max-width: 767px) {
      width: 100%;
      max-width: inherit;
      margin: 0;
      top: 0;
      transform: inherit;
    }

    .modal-content {
      background: $blue;
      border-radius: 0px;
      height: 100%;
      display: block;
      border: none;
      @media (max-width: 767px) {
        min-height: 89.7vh;
        max-height: 100%;
      }
      .modal-header {
        padding: 0 !important;
        border: none;
        display: block;
        width: 100%;
        text-align: right !important;
        position: absolute !important;
        z-index: 1;

        @media (max-width: 1024px) {
          text-align: right !important;
          padding: 0px 13px 0 !important;
          position: relative !important;
        }
        @media (max-width: 991px) {
          text-align: right !important;
          padding: 13px 13px 0 !important;
          position: relative !important;
        }
        @media (max-width: 767px) {
          text-align: right !important;
          padding: 0px 13px 0 !important;
          position: relative !important;
        }

        button {
          background-image: var(--bs-btn-close-bg) !important;
          position: relative;
          top: 15px;
          right: 20px;
          background-size: 10px;
          opacity: 1;
          &:focus,
          &:active {
            box-shadow: none;
            opacity: 1;
          }
        }

        @media (max-width: 1024px) {
          button {
            // background-image: url(../../assets/images/close-btn.svg) !important;
            background-image: var(--bs-btn-close-bg) !important;
            right: 15px;
            top: 15px;
            position: absolute;
          }
        }
        @media (max-width: 991px) {
          button {
            background-image: var(--bs-btn-close-bg) !important;
            right: 15px;
            top: 15px;
            position: absolute;
          }
        }
        @media (max-width: 767px) {
          button {
            background-image: var(--bs-btn-close-bg) !important;
            right: 15px;
            top: 10px;
            position: absolute;
          }
        }
      }
      .modal-body {
        padding: 41px 39px;

        @media (max-width: 991px) {
          padding: 20px 35px 41px 30px;
        }

        @media (max-width: 767px) {
          padding: 20px 35px 41px 30px;
        }
        h2 {
          padding-top: 0px !important;
          text-align: left !important;
          font-size: 46.4px;
          font-family: $worksans-thin;
          text-transform: uppercase;
          letter-spacing: -0.04em;
          font-weight: 100;
          margin-bottom: 48px;

          @media (max-width: 1024px) {
            text-align: left !important;
            padding-top: 15px !important;
          }
          @media (max-width: 991px) {
            text-align: left !important;
            padding-top: 15px !important;
            font-size: 36.4288px;
          }
          @media (max-width: 767px) {
            text-align: left !important;
            padding-top: 15px !important;
            font-size: 36.4288px;
          }
        }
        form {
          .row {
            margin: 0 -5px;
            .col-12 {
              padding: 0 5px;
            }
            .col-md-6,
            .col-lg-6,
            .col-xl-6 {
              padding: 0 5px;
            }
          }
          label {
            text-transform: uppercase;
            font-size: 19.2px;
            font-family: $worksans-semibold;
            letter-spacing: normal;
            color: $text-black;
            opacity: 1;
            letter-spacing: -1px;
            b {
              font-weight: normal;
              opacity: 60%;
              font-size: 16px;
              margin-left: 5px;
            }
          }
          label.sublabel {
            text-transform: uppercase;
            font-size: 16px;
          }
          .sublabel.form-check label {
            text-transform: uppercase;
            font-size: 16px;
            margin-left: 10px;
            margin-top: 5px;
            cursor: pointer;
          }
          input[type="text"],
          input[type="email"] {
            @include input-text2;
            background: $green !important;
            border-radius: 50px;
            border: 2px solid $green !important;
            height: 47px;
            font-size: 16px;
            text-transform: uppercase;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 11px;
            font-family: $worksans-semibold;
            @media (max-width: 767px) {
              padding-top: 7px;
            }

            &:focus {
              border: 2px solid $blue !important;
              outline: 2px solid #181818 !important;
            }
          }
          textarea {
            @include input-text2;
            background: $green !important;
            padding-top: 9px;
            border-radius: 26.4736px;
            padding-left: 20px;
            padding-right: 20px;
            height: 100px;
            font-size: 16px;
            text-transform: uppercase;
            font-family: $worksans-semibold;
            border: 2px solid $green !important;
            &:focus {
              border: 2px solid $blue !important;
              outline: 2px solid #181818 !important;
            }
          }

          .form-check-input {
            background-color: $green;
            --bs-form-check-bg-image: auto;
            width: 1.5em;
            height: 1.5em;
            border-color: $black;
            border-radius: 50%;
            cursor: pointer;
            &:focus,
            &:hover {
              box-shadow: none;
            }
          }
          .form-check-input:checked {
            background-image: url(../images/check.svg);
            border-color: $black;
            background-size: 92%;
          }
          // .form-check-input:checked[type=radio] {
          //    background-color: $green;
          //    --bs-form-check-bg-image: auto;
          //    width: 1.5em;
          //    height: 1.5em;
          //    &:focus, &:hover {
          //       box-shadow: none
          //    }
          // }
          button.fillbutton {
            @include fillbutton;
            font-weight: 800;
            font-size: 17.6px;
            // background: $orange;
            text-transform: uppercase;
            // color: $text-black;
            font-family: $worksans-bold;
            letter-spacing: -0.7px;
            width: 305px;
            padding-top: 11px;
            // &:hover {
            //    background: $orange !important;
            // }
          }
        }

        .thankmsg {
          height: 60vh;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          p {
            // color: $text-black;
            opacity: 1;
            letter-spacing: -0.5px;
            font-size: 30px;
            margin-top: 30px;
          }

          svg {
            width: 100px;
            display: block;
            margin: 45px auto 0;
          }

          .path {
            stroke-dasharray: 1000;
            stroke-dashoffset: 0;
            &.circle {
              -webkit-animation: dash 0.9s ease-in-out;
              animation: dash 0.9s ease-in-out;
            }
            &.line {
              stroke-dashoffset: 1000;
              -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
              animation: dash 0.9s 0.35s ease-in-out forwards;
            }
            &.check {
              stroke-dashoffset: -100;
              -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
              animation: dash-check 0.9s 0.35s ease-in-out forwards;
            }
          }

          @-webkit-keyframes dash {
            0% {
              stroke-dashoffset: 1000;
              -webkit-stroke-dashoffset: 1000;
            }
            100% {
              stroke-dashoffset: 0;
              -webkit-stroke-dashoffset: 0;
            }
          }

          @keyframes dash {
            0% {
              stroke-dashoffset: 1000;
              -webkit-stroke-dashoffset: 1000;
            }
            100% {
              stroke-dashoffset: 0;
              -webkit-stroke-dashoffset: 0;
            }
          }

          @-webkit-keyframes dash-check {
            0% {
              stroke-dashoffset: -100;
              -webkit-stroke-dashoffset: -100;
            }
            100% {
              stroke-dashoffset: 900;
              -webkit-stroke-dashoffset: 900;
            }
          }

          @keyframes dash-check {
            0% {
              stroke-dashoffset: -100;
              -webkit-stroke-dashoffset: -100;
            }
            100% {
              stroke-dashoffset: 900;
              -webkit-stroke-dashoffset: 900;
            }
          }

          h2 {
            text-align: center !important;
            margin-bottom: 0px;
          }
        }
      }
      .moreContent {
        max-height: 300px;
        overflow-y: auto;
        padding-right: 10px;
      }

      @include modalbox;
    }
  }
}

.pageloaderBox {
  position: fixed;
  top: -1px !important;
  height: 100%;
  width: 100%;
  background: $innerbg;
  padding: 18px 15px !important;

  @media (max-width: 767px) {
    padding: 18px 15px !important;
  }
  .modal-dialog {
    margin: auto;
    padding: 0;
    max-width: none;
    height: 100%;
    // background-image: url(https://d2rweuuav95dhv.cloudfront.net/images/pageloader-bg.gif);
    // background-image: url(https://d2rweuuav95dhv.cloudfront.net/images/welcome-bg.gif);
    // background-repeat: no-repeat;
    // border-radius: 18px;
    // background-position: center;
    // background-size: 100% 100%;

    @media (max-width: 767px) {
      // height: 94%;
      // margin: 20px 15px;
    }

    .modal-content {
      border-radius: 18px;
      height: 100%;
      width: 100%;
      background: transparent;
      // background-color: rgba(37, 33, 30, 1);
      padding: 15px 10px;
      border: none;
      position: relative;
      overflow: hidden;
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        background-color: rgba(37, 33, 30, 0.4);
      }

      .modal-body {
        // background-image: url(../images/pageloader-bg.gif);

        .loaderContent {
          position: relative;
          z-index: 1;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;

          h1.spinnerText {
            font-size: $font-size-20;
            // font-family: $worksans-regular;
            font-family: $worksans-medium;
            font-weight: 600;
            margin-bottom: 0;
            text-align: left;
            .verifiedsteps {
              list-style: none;
              text-align: left;
              line-height: 1.5;
              padding-left: 0;
              img {
                filter: invert(1);
              }
            }
          }

          .spintextone:before {
            content: "You're In :)";
            -webkit-animation: spinloader 2s ease infinite; /* Include -webkit- prefix */
            animation: spinloader 2s ease infinite;
          }
          .spintextthree:before {
            content: "Rental Agreement Signed :)";
            -webkit-animation: spinloader 2s ease infinite; /* Include -webkit- prefix */
            animation: spinloader 2s ease infinite;
          }
          .spintextfour:before {
            content: "ID Verified :)";
            -webkit-animation: spinloader 2s ease infinite; /* Include -webkit- prefix */
            animation: spinloader 2s ease infinite;
          }
          .spintextfive:before {
            content: "Security Deposit :)";
            -webkit-animation: spinloader 2s ease infinite; /* Include -webkit- prefix */
            animation: spinloader 2s ease infinite;
          }

          .spintexttwo:after {
            content: "Loading Arrival Instructions";
            -webkit-animation: spinloader 2s ease infinite; /* Include -webkit- prefix */
            animation: spinloader 2s ease infinite;
          }

          @keyframes spinloader {
            0%,
            25%,
            50%,
            75%,
            100% {
              color: rgba(255, 237, 73, 1);
            }
            25% {
              color: rgba(255, 192, 98, 1);
            }
            50% {
              color: rgba(123, 232, 255, 1);
            }
            75% {
              color: rgba(164, 255, 102, 1);
            }
          }
        }
      }
    }
  }

  .welcomebgvideomodal {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0px;
    z-index: 0;
    video {
      max-width: 100%;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    @media (max-width: 1024px) {
      display: block;
    }

    @media (max-width: 991px) {
      display: none;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }

  .welcomebgvideomodalmobile {
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0px;
    z-index: 0;
    video {
      max-width: 100%;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    @media (max-width: 1024px) {
      display: none;
    }

    @media (max-width: 991px) {
      display: block;
    }
    @media (max-width: 767px) {
      display: block;
    }
  }
}

// Misc

.signOndotted .modal-backdrop {
  display: none;
}

// Responsive view

@media (max-width: 1440px){
  .mainpage{
    .content {
      figure{
        margin-bottom: 35px;
      }
    }
  }
}

@media (max-width: 1340px){
  .mainpage{
    .content {
      figure{
        text-align: center;
        img{
          width: 325px;
        }
      }
      h1{
        font-size: 32px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .mainpage {
    position: relative;
    height: 100%;
    padding: 0 30px;
    .content {
      position: relative;
      height: 90vh;
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      margin: auto;
      flex-direction: column;
      h1 {
        color: $green !important;
        @include heading1;
        // font-size: 52.2px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        font-family: $worksans-bold;
        letter-spacing: -0.7px;
        margin-bottom: 15px;
      }
      figure {
        // width: 371px;
        img {
          // width: 371px;
        }
        // margin-bottom: 100px;
      }
      @media (max-width: 767px) {
        figure {
          width: 300px;
          img {
            width: 300px;
          }

          // margin-bottom: 100px;
        }
      }
      button.fillbutton {
        @include fillbutton;
        font-weight: 800;
        font-size: 17.6px;
        background: $orange;
        text-transform: uppercase;
        color: $text-black;
        font-family: $worksans-bold;
        letter-spacing: -0.7px;
        width: 365px;
        padding-top: 11px;
        &:hover {
          background: $orange !important;
        }
      }
    }
  }

  .welcomePage {
    position: relative;
    // height: 100vh;
    padding: 0 30px;
    .content {
      position: relative;
      height: 91vh;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 512px;

      #welcometext {
        position: relative;
        @include heading1;
      }

      #welcometext:after {
        content: "Welcome!";
        position: absolute;
        top: -42px;
        -webkit-animation: spin 8s ease infinite; /* Include -webkit- prefix */
        animation: spin 8s linear infinite;
      }

      @keyframes spin {
        0% {
          visibility: hidden;
        }
        9% {
          visibility: visible;
          content: "Welcome!";
        }
        18% {
          visibility: hidden;
        }
        27% {
          visibility: visible;
          content: "Let's go!";
        }
        36% {
          visibility: hidden;
        }
        45% {
          visibility: visible;
          content: "Can't Waitttt";
        }
        54% {
          visibility: hidden;
        }
        63% {
          visibility: visible;
          content: "Get Excited!!";
        }
        72% {
          visibility: hidden;
        }
        81% {
          visibility: visible;
          content: "YASSSSSS";
        }
        90% {
          visibility: hidden;
        }
        100% {
          visibility: visible;
          content: "Get packin'!";
        }
      }
    }

    .welcomebg {
      position: fixed;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
      background-image: url(https://d2rweuuav95dhv.cloudfront.net/images/welcome-bg.gif);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      left: 0;
      // filter: blur(11px);
      // .overlay {
      //    background-color: rgba(0,0,0,0.2);
      //    position: absolute;
      //    height: 100vh;
      //    width: 100%;
      // }
      // video {
      //    position: absolute;
      //    height: 100vh;
      //    width: 100%;
      //    object-fit: cover;
      // }
    }
    .react-datepicker-wrapper {
      width: 100%;
      input.input-text {
        @include input-text;
        width: 100%;
        &:focus-visible {
          outline: none;
          padding: 0.39rem 15px;
        }
      }
      button {
        &::after {
          background-color: $white;
          color: $black;
          border-radius: 50%;
          height: 16px;
          display: flex !important;
          align-items: center;
          justify-content: center;
          width: 16px;
          padding: 9px 10px 11px;
          text-align: center;
          content: "×";
        }
      }
    }
    .contentFooter {
      position: absolute;
      bottom: 0;
      width: auto;
      text-align: center;
      left: 50%;
      transform: translate(-50%, -20px);
    }
    &::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      background: none;
      height: 100vh;
      width: 100%;
      backdrop-filter: blur(8px); /* Apply blur effect to the pseudo-element */
      -webkit-backdrop-filter: blur(8px);
      z-index: 0; /* Ensure the pseudo-element is behind the container */
    }

    .welcomebgvideo {
      display: block;
      position: fixed;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0px;
      z-index: -1;
      video {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .welcomebgvideomobile {
      display: none;
      position: fixed;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0px;
      z-index: -1;
      video {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  // inner pages

  .checkedinpage {
    height: 100vh;
    padding: 0 10px 10px;
    // background: $innerbg;

    .card-section {
      width: 512px;

      .cardYellow {
        background: $yellow;
        border-radius: 18px;
        padding: 8px 8px 20px;
        margin-bottom: 5px;
        figure {
          width: 100%;
          // max-height: 221.66px;
          // min-height: 221.66px;
          // overflow: hidden;
          border-radius: 11px;
          margin-bottom: 0;
          img {
            max-width: 100%;
            // max-height: 221.66px;
            // min-height: 221.66px;
            border-radius: 11px;
            object-fit: fill;
          }
        }

        .content {
          display: flex;
          justify-content: space-between;
          padding: 15px 14px 5px;
          .checkedinDate {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 50%;

            &:first-child {
              position: relative;
              width: 50%;
              padding-right: 30px;
              &::after {
                position: absolute;
                content: "";
                width: 1px;
                height: 24px;
                background: rgba(0, 0, 0, 0.08);
                right: 10px;
                top: 10px;
              }
            }
            &:last-child {
              padding-left: 30px;
            }
          }
        }
      }
      .cardOrange {
        background: $orange;
        border-radius: 18px;
        padding: 8px 8px 8px;
        .content {
          display: flex;
          flex-direction: column;
          padding: 5px 14px 5px;
          .checkedinDate {
            display: flex;
            flex-direction: column;

            label {
              @include label2;
            }
          }

          .react-tel-input {
            font-family: $worksans-regular;
            position: relative;
            input {
              @include input-text2;
              width: 100%;
              padding-left: 62px;
            }
            .flag-dropdown {
              background: transparent;
              border: none;

              .selected-flag {
                background: transparent;
              }

              &::after {
                position: absolute;
                width: 1px;
                height: 20px;
                background: rgba(0, 0, 0, 0.1);
                content: "";
                top: 15px;
                right: -12px;
              }
            }
            .country-list {
              width: 470px;
              border-radius: 12px;
            }
          }

          p {
            margin: 15px 0 5px;
          }
          form {
            label {
              margin-bottom: 5px;
            }
          }
        }
        hr {
          margin: 15px 0 12px;
        }
      }
    }
  }

  .checkinginpage {
    height: auto;
    padding: 0 10px 10px;
    // background: $innerbg;

    .card-section {
      width: 512px;
      .accrodianBlock {
        .cardYellow {
          background: $yellow;
          border-radius: 18px;
          padding: 17px 0 15px;
          margin-bottom: 5px;
          border: none;

          .accordion-header {
            button {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: -2px;
              }
            }
            button.collapsed {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              display: flex;
              align-items: baseline;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: 0px;
                position: relative;
                left: 0;
                top: 1px;
                width: 17px;
              }
            }
            h2 {
              margin-top: 0px;
              font-family: $worksans-medium;
              letter-spacing: -0.2px;
              display: flex;
              align-items: center;
              span {
                margin-left: 5px;
              }
            }
          }
          .accordion-body {
            padding-top: 15px;
            padding-bottom: 7px;
            .swiperSliderone {
              .swiper {
                height: 210px;
                .swiper-slide {
                  .form-check {
                    position: relative;
                    .form-check-input {
                      width: 100%;
                      height: 180px;
                      background: none;
                      // border-radius: 14px;
                      box-shadow: none;
                      border: none;
                      margin-top: 0;
                      &:hover,
                      &:focus,
                      &:active {
                        box-shadow: none;
                        border: none;
                        background: none;
                      }

                      &:checked[type="checkbox"] {
                        background: none;
                        &:hover,
                        &:focus,
                        &:active {
                          box-shadow: none;
                          border: none;
                          background: none;
                        }
                      }
                    }

                    .form-check-input:checked[type="checkbox"] + label {
                      position: absolute;
                      top: 0;
                      font-size: $font-size-18;
                      font-family: $worksans-medium;
                      font-weight: 500;
                      color: $text-black;
                      left: 0;
                      width: 100%;
                      height: 180px;
                      border-radius: 14px;
                      background: rgba(255, 255, 255, 1);
                      padding: 65px 14px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      line-height: 21px;
                      letter-spacing: -0.2px;
                      opacity: 1;
                    }
                    .form-check-input[type="checkbox"] + label {
                      position: absolute;
                      top: 0;
                      font-size: $font-size-18;
                      font-family: $worksans-medium;
                      font-weight: 500;
                      left: 0;
                      width: 100%;
                      height: 180px;
                      border-radius: 14px;
                      background: rgba(30, 28, 34, 0.1);
                      padding: 65px 14px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      line-height: 21px;
                      opacity: 1;
                      letter-spacing: -0.2px;
                      opacity: 0.5;
                    }

                    label {
                      color: $text-label-checkbox;
                    }
                  }
                }
                .swiper-slide.swiper-slide-active {
                  .form-check {
                    position: relative;
                    .form-check-input {
                      width: 100%;
                      height: 180px;
                      background: none;
                      // border-radius: 14px;
                      box-shadow: none;
                      border: none;
                      margin-top: 0;
                      &:hover,
                      &:focus,
                      &:active {
                        box-shadow: none;
                        border: none;
                        background: none;
                      }

                      &:checked[type="checkbox"] {
                        background: none;
                        &:hover,
                        &:focus,
                        &:active {
                          box-shadow: none;
                          border: none;
                          background: none;
                        }
                      }
                    }

                    .form-check-input:checked[type="checkbox"] + label {
                      position: absolute;
                      top: 0;
                      font-size: $font-size-18;
                      font-family: $worksans-medium;
                      font-weight: 500;
                      color: $text-black;
                      left: 0;
                      width: 100%;
                      height: 180px;
                      border-radius: 14px;
                      background: rgba(255, 255, 255, 1);
                      padding: 65px 14px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      line-height: 21px;
                      letter-spacing: -0.2px;
                      opacity: 1;
                    }
                    .form-check-input[type="checkbox"] + label {
                      position: absolute;
                      top: 0;
                      font-size: $font-size-18;
                      font-family: $worksans-medium;
                      font-weight: 500;
                      left: 0;
                      width: 100%;
                      height: 180px;
                      border-radius: 14px;
                      background: rgba(30, 28, 34, 0.1);
                      padding: 65px 14px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      line-height: 21px;
                      color: $text-black;
                      opacity: 1;
                    }
                  }
                }
                .swiper-pagination {
                  bottom: 0;
                  text-align: left;
                  font-family: $worksans-semibold;
                  font-weight: 500;
                  font-size: $font-size-12;
                  color: $text-black;
                  opacity: 50%;
                  letter-spacing: -1.3px;
                }

                .swiper-slide.autowidthchange {
                  width: auto !important;

                  .id_card_block.image-uploaded {
                    position: relative;
                    overflow: hidden;
                    display: inline-block;
                    width: auto;
                    height: 133px;
                    .id_card_uploaded {
                      width: auto;
                      figure {
                        width: auto;
                        img {
                          width: auto;
                        }
                      }
                    }
                  }
                  .id_card_block_selfie.image-uploaded {
                    position: relative;
                    overflow: hidden;
                    display: inline-block;
                    width: auto;
                    height: 133px;
                    .id_card_uploaded {
                      width: auto;
                      figure {
                        width: auto;
                        img {
                          width: auto;
                        }
                      }
                    }
                  }
                }
              }
              &.houseRulesSwiperLink {
                .swiper {
                  .swiper-slide {
                    .form-check {
                      .form-check-input[type=checkbox] + label {
                        padding: 15px 14px;
                      }
                    }
                  }
                }
              }
            }
            .swiperSliderone .swiper .swiper-slide-active {
              .id_card_uploaded {
                background: rgba(30, 28, 34, 0.1);
                border: none;
              }
            }

            button {
              div {
                text-transform: lowercase;
                &::first-letter {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
        .cardOrange {
          background: $orange;
          border-radius: 18px;
          padding: 17px 0 15px;
          margin-bottom: 5px;
          border: none;

          .accordion-header {
            button {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: -2px;
              }
            }
            button.collapsed {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              display: flex;
              align-items: baseline;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: 0px;
                position: relative;
                left: 0;
                top: -1px;
                width: 17px;
              }
            }
            h2 {
              margin-top: 0px;
              font-family: $worksans-medium;
              letter-spacing: -0.2px;
              display: flex;
              align-items: center;
              span {
                margin-left: 5px;
              }
            }
          }
          .accordion-body {
            padding-top: 15px;
            padding-bottom: 7px;
            .readagreement {
              max-height: 233px;
              overflow-y: auto;
              margin-bottom: 20px;
              padding-right: 10px;
            }
            button {
              div {
                text-transform: lowercase;
                &::first-letter {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
        .cardBlue {
          background: $blue;
          border-radius: 18px;
          padding: 17px 0 15px;
          margin-bottom: 5px;
          border: none;

          .accordion-header {
            button {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: -2px;
              }
            }
            button.collapsed {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              display: flex;
              align-items: baseline;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: 0px;
                position: relative;
                left: 0;
                top: 1px;
                width: 17px;
              }
            }
            h2 {
              margin-top: 0px;
              font-family: $worksans-medium;
              letter-spacing: -0.2px;
              display: flex;
              align-items: center;
              span {
                margin-left: 5px;
              }
            }
          }
          .accordion-body {
            padding-top: 15px;
            padding-bottom: 7px;
            h5 {
              margin-bottom: 3px;
            }
            .sub-title {
              font-family: $worksans-regular;
              font-weight: 400;
              color: $text-black;
              font-size: $font-size-13;
              display: block;
              opacity: 60%;
              line-height: 15.76px;
            }
            .sub-title-2 {
              font-family: $worksans-regular;
              font-weight: 400;
              color: $text-black;
              font-size: $font-size-13;
              display: block;
              opacity: 70%;
              line-height: 15.76px;
            }

            .swiperSliderone {
              .swiper {
                height: 160px;

                // .id_screen_upd {
                //    position: relative;
                //    overflow: hidden;
                //    display: inline-block;
                //    width: 100%;
                //    height: 133px;
                //  }

                //  .btn {
                //    border: 1px dashed rgba(0, 0, 0, 0.3);
                //    background-color: none;
                //    padding: 8px 20px;
                //    border-radius: 10px;
                //    font-size: 20px;
                //    font-weight: bold;
                //    width: 100%;
                //    height: 133px;
                //    p {
                //       @include paragraph;
                //       text-transform: lowercase;
                //          &::first-letter {
                //             text-transform: uppercase;
                //          }

                //       span {
                //          text-transform: uppercase;
                //       }
                //    }
                //  }

                //  .id_screen_upd input[type=file] {
                //    font-size: 100px;
                //    position: absolute;
                //    left: 0;
                //    top: 0;
                //    opacity: 0;
                //    width: 100%;
                //  }

                //  .swiper-slide.swiper-slide-active {

                //    .btn {
                //       border: none;
                //       background-color: rgba(30, 28, 34, 0.1);
                //       padding: 8px 20px;
                //       border-radius: 10px;
                //       font-size: 20px;
                //       font-weight: bold;
                //       width: 100%;
                //       height: 133px;
                //       p {
                //          @include paragraph;
                //          text-transform: lowercase;
                //             &::first-letter {
                //                text-transform: uppercase;
                //             }

                //          span {
                //             text-transform: uppercase;
                //          }
                //       }
                //     }

                //  }

                .id_card_block {
                  position: relative;
                  overflow: hidden;
                  display: inline-block;
                  width: 100%;
                  height: 133px;
                }

                .id_card_uploaded {
                  border: 1px dashed rgba(0, 0, 0, 0.3);
                  background: none;
                  padding: 0;
                  border-radius: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  width: 100%;
                  height: 133px;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: center;
                  text-align: center;
                  overflow: hidden;
                  figure {
                    height: 133px;
                    width: 100%;
                    margin: 0;
                    position: relative;
                    img {
                      height: 133px;
                      width: 100%;
                      object-fit: contain;
                    }
                    span.deletePreview {
                      position: absolute;
                      top: 1px;
                      right: 5px;
                      padding: 0;
                      background: none;
                      cursor: pointer;
                      img {
                        width: 28px;
                        height: 28px;
                      }
                    }
                  }
                }
                .id_card_block_selfie {
                  position: relative;
                  overflow: hidden;
                  display: inline-block;
                  width: 100px;
                  height: 133px;
                }

                .id_card_uploaded_selfie {
                  border: 1px dashed rgba(0, 0, 0, 0.3);
                  background: none;
                  padding: 0;
                  border-radius: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  width: 100px;
                  height: 133px;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: center;
                  text-align: center;
                  overflow: hidden;
                  figure {
                    height: 133px;
                    width: 100%;
                    margin: 0;
                    position: relative;
                    img {
                      height: 133px;
                      width: 100%;
                      object-fit: contain;
                    }

                    span.deletePreview {
                      position: absolute;
                      top: 1px;
                      right: 5px;
                      padding: 0;
                      background: none;
                      cursor: pointer;
                      img {
                        width: 28px;
                        height: 28px;
                      }
                    }
                  }
                }

                p.id_label {
                  font-size: $font-size-16 !important;
                  color: $text-para-2 !important;
                  margin-top: 5px;
                  @include paragraph;

                  text-transform: lowercase;
                  &::first-letter {
                    text-transform: uppercase;
                  }

                  span {
                    text-transform: uppercase;
                  }
                }
                .id_card {
                  border: 1px dashed rgba(0, 0, 0, 0.3);
                  background: none;
                  padding: 8px 20px;
                  border-radius: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  width: 100%;
                  height: 133px;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: center;
                  text-align: center;

                  img {
                    width: 32px;
                  }

                  p.id_label {
                    font-size: $font-size-16 !important;
                    color: $text-para-2 !important;
                    margin-top: 5px;
                    @include paragraph;

                    text-transform: lowercase;
                    &::first-letter {
                      text-transform: uppercase;
                    }

                    span {
                      text-transform: uppercase;
                    }
                  }
                }

                .id_card_selfie {
                  border: 1px dashed rgba(0, 0, 0, 0.3);
                  background: none;
                  padding: 15px 20px 0;
                  border-radius: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  width: 100px;
                  height: 133px;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: center;
                  text-align: center;

                  img {
                    width: 32px;
                  }

                  p.id_label {
                    font-size: $font-size-16;
                    color: $text-para-2;
                    margin-top: 5px;
                    @include paragraph;

                    text-transform: lowercase;
                    &::first-letter {
                      text-transform: uppercase;
                    }

                    span {
                      text-transform: uppercase;
                    }
                  }
                }

                .swiper-slide.swiper-slide-active {
                  .id_card {
                    border: none;
                    background-color: rgba(30, 28, 34, 0.1);
                    padding: 8px 20px;
                    border-radius: 10px;
                    font-size: 20px;
                    font-weight: bold;
                    width: 100%;
                    height: 133px;
                    img {
                      width: 32px;
                    }
                    p {
                      @include paragraph;
                      text-transform: lowercase;
                      &::first-letter {
                        text-transform: uppercase;
                      }

                      span {
                        text-transform: uppercase;
                      }
                    }
                  }

                  .id_card_selfie {
                    border: none;
                    background-color: rgba(30, 28, 34, 0.1);
                    padding: 15px 20px 0;
                    border-radius: 10px;
                    font-size: 20px;
                    font-weight: bold;
                    width: 100px;
                    height: 133px;
                    img {
                      width: 32px;
                    }
                    p {
                      @include paragraph;
                      text-transform: lowercase;
                      &::first-letter {
                        text-transform: uppercase;
                      }

                      span {
                        text-transform: uppercase;
                      }
                    }
                  }
                }

                .swiper-pagination {
                  bottom: 0;
                  text-align: left;
                  font-family: $worksans-semibold;
                  font-weight: 500;
                  font-size: $font-size-12;
                  color: $text-black;
                  opacity: 50%;
                  letter-spacing: -1.3px;
                }

                .swiper-slide.autowidthchange {
                  width: auto !important;

                  .id_card_block.image-uploaded {
                    position: relative;
                    overflow: hidden;
                    display: inline-block;
                    width: auto;
                    height: 133px;
                    .id_card_uploaded {
                      width: auto;
                    }
                    figure {
                      width: auto;
                      img {
                        width: auto;
                      }
                    }
                  }
                  .id_card_block_selfie.image-uploaded {
                    position: relative;
                    overflow: hidden;
                    display: inline-block;
                    width: auto;
                    height: 133px;
                    .id_card_uploaded {
                      width: auto;
                    }
                    figure {
                      width: auto;
                      img {
                        width: auto;
                      }
                    }
                  }
                }
              }
            }
            .swiperSliderone .swiper .swiper-slide-active {
              .id_card_uploaded {
                background: rgba(30, 28, 34, 0.1);
                border: none;
              }
            }

            button {
              div {
                text-transform: lowercase;
                &::first-letter {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
        .cardGreen {
          background: $green;
          border-radius: 18px;
          padding: 17px 0 15px;
          margin-bottom: 5px;
          border: none;

          .accordion-header {
            button {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: -2px;
              }
            }
            button.collapsed {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              display: flex;
              align-items: baseline;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: 0px;
                position: relative;
                left: 0;
                top: -1px;
                width: 17px;
              }
            }

            h2 {
              margin-top: 0px;
              font-family: $worksans-medium;
              letter-spacing: -0.2px;
              display: flex;
              align-items: center;
              span {
                margin-left: 5px;
              }
            }
          }
          .accordion-body {
            padding-top: 15px;
            padding-bottom: 7px;
            .radiobox {
              .form-check-input:checked[type="radio"] {
                border: 6px solid $black;
                background: none;
              }
              .form-check-input[type="radio"] {
                border: 1px solid rgba(0, 0, 0, 0.3);
                background: none;

                &:focus,
                &:hover,
                &:active {
                  box-shadow: 0 0 0 0.25rem rgb(93 93 93 / 25%) !important;
                }
              }
              label {
                font-family: $worksans-medium;
                font-size: $font-size-16;
                color: $text-black;
                font-weight: 500;
                opacity: 1;
              }
            }

            button {
              div {
                text-transform: lowercase;
                &::first-letter {
                  text-transform: uppercase;
                }
              }
            }

            ul {
              padding-left: 15px;
              margin-bottom: 60px;
              li {
                @include paragraph2;
                margin-bottom: 7px;
                opacity: 70% !important;
                &::marker {
                  position: absolute;
                  font-size: 95%;
                }
              }
            }
          }
        }

        .accrodionCollapsed {
          pointer-events: none;

          button.collapsed {
            background: transparent;
            box-shadow: none;
            padding-bottom: 0;
            padding-top: 0;
            display: flex;
            align-items: baseline;
            &::after {
              display: none;
            }

            img {
              margin-right: 7px;
              margin-top: 0px;
              position: relative;
              left: 0;
              top: 1px;
              width: 17px;
            }
          }
        }
      }
    }
  }

  .reservationnpage {
    height: 100%;
    padding: 0 10px;
    // background: $innerbg;

    .card-section {
      width: 512px;
      .cardGreen {
        background: $green;
        border-radius: 18px;
        padding: 8px 8px 20px;
        margin-bottom: 5px;

        .reserved {
          position: relative;
          .reservedDate {
            position: absolute;
            display: flex;
            justify-content: space-between;
            padding: 15px 14px 5px;
            width: 100%;
            z-index: 1;
            .checkedinDate {
              display: flex;
              flex-direction: column;
              position: relative;
              width: 50%;
              label,
              .dataLabel {
                color: rgba(255, 255, 255, 1);
              }
              &:first-child {
                position: relative;
                width: 50%;
                padding-right: 30px;
                &::after {
                  position: absolute;
                  content: "";
                  width: 1px;
                  height: 24px;
                  background: rgba(255, 255, 255, 0.08);
                  right: 10px;
                  top: 10px;
                }
              }
              &:last-child {
                padding-left: 30px;
              }
            }
          }
          figure {
            width: 100%;
            // max-height: 150px;
            // min-height: 150px;
            // max-height: 221.66px;
            // min-height: 221.66px;
            // overflow: hidden;
            border-radius: 11px;
            margin-bottom: 0;
            position: relative;
            overflow: hidden;
            img {
              max-width: 100%;
              // max-height: 150px;
              // min-height: 150px;
              // max-height: 221.66px;
              // min-height: 221.66px;
              border-radius: 11px;
              object-fit: cover;
            }

            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-image: linear-gradient(180deg, #00000099, transparent);
            }
          }
        }
        .content {
          display: flex;
          justify-content: space-between;
          padding: 15px 14px 5px;
          align-items: center;
          .checkedinDate {
            display: flex;
            flex-direction: column;
            position: relative;
            // width: 50%;
            width: 50px;
            text-align: right;
            label {
              line-height: 16.42px;
              color: $text-black;
              opacity: 80%;
            }
            img {
              width: 38px;
            }
            &:first-child {
              position: relative;
              // width: 50%;
              width: calc(100% - 50px);
              padding-right: 0px;
              text-align: left;
              &::after {
                position: absolute;
                content: "";
                width: 1px;
                height: 24px;
                background: rgba(0, 0, 0, 0.08);
                right: 10px;
                top: 10px;
                display: none;
              }
            }
            &:last-child {
              padding-left: 10px;
            }
          }
        }
        // video {
        //    width: 100%;
        //    height: 533px;
        //    border: none;
        // }

        video {
          height: auto;
          width: 100%;
          // aspect-ratio: 9 / 16;
          // aspect-ratio: 1.17 / 1;
          border: 0;
          border-radius: 11px;
        }

        // iframe {
        //    height: auto;
        //    width: 100%;
        //    // aspect-ratio: 9 / 16;
        //    aspect-ratio: 1.17 / 1;
        //    border: 0;
        //    border-radius: 11px;
        // }
      }
      .cardBlue {
        background: $blue;
        border-radius: 18px;
        padding: 8px 20px 20px;
        margin-bottom: 5px;
        h2 {
          margin: 10px 0;
          font-family: $worksans-medium;
        }
        figure {
          width: 100%;
          // max-height: 221.66px;
          // min-height: 221.66px;
          // overflow: hidden;
          border-radius: 11px;
          margin-bottom: 20px;
          img {
            max-width: 100%;
            // max-height: 221.66px;
            // min-height: 221.66px;
            border-radius: 11px;
            object-fit: fill;
          }
        }
        p {
          color: $text-black;
          opacity: 80%;
          line-height: 16.42px;
        }
        .content {
          display: flex;
          justify-content: space-between;
          padding: 5px 0px 5px;
          width: 100%;
          .checkedinDate {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 50%;

            &:first-child {
              position: relative;
              width: 50%;
              padding-right: 30px;
              &::after {
                position: absolute;
                content: "";
                width: 1px;
                height: 24px;
                background: rgba(0, 0, 0, 0.08);
                right: 10px;
                top: 10px;
              }
            }
            &:last-child {
              padding-left: 30px;
            }

            span.dataLabel {
              white-space: break-spaces;
              word-wrap: break-word;
            }
          }
        }
      }
      .cardYellow {
        background: $yellow;
        border-radius: 18px;
        padding: 8px 20px 20px;
        margin-bottom: 5px;
        h2 {
          margin: 10px 0;
          font-family: $worksans-medium;
        }
        p {
          color: $text-black;
          opacity: 80%;
          line-height: 16.42px;
        }

        button {
          text-transform: lowercase;
          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
      .cardGreen2 {
        background: $green;
        border-radius: 18px;
        padding: 8px 20px 20px;
        margin-bottom: 5px;
        h2 {
          margin: 10px 0;
          font-family: $worksans-medium;
        }
        p {
          color: $text-black;
          opacity: 80%;
          line-height: 16.42px;
        }
        .localMap {
          background: $white;
          padding: 10px;
          border-radius: 18px;

          figure {
            width: 100%;
            max-height: 145px;
            min-height: 145px;
            overflow: hidden;
            border-radius: 6px;
            margin-bottom: 0;
            img {
              width: 100%;
              max-height: 145px;
              min-height: 145px;
              border-radius: 6px;
              object-fit: cover;
            }
          }
          .content {
            display: flex;
            justify-content: space-between;
            padding: 15px 0px 5px;
            align-items: center;
            flex-direction: column;
            .dataLabel {
              font-weight: 700;
            }
            .card-subtitle {
              font-size: $font-size-12;
              color: $text-para;
              font-family: $worksans-regular;
              display: block;
              width: 100%;
            }
            button {
              div {
                text-transform: lowercase;
                &::first-letter {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
      }
      .cardPink{
         background: $pink;
       }
      .cardOrange {
        background: $orange;
        border-radius: 18px;
        padding: 8px 20px 20px;
        margin-bottom: 5px;
        h2 {
          margin: 10px 0;
          font-family: $worksans-medium;
        }
        p {
          color: $text-black;
          opacity: 80%;
          line-height: 16.42px;
        }

        button {
          text-transform: lowercase;
          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
      .cardBlank {
        background: none;
        padding: 20px 15px 40px;
        margin-bottom: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
          margin: 10px 0 25px;
          color: $text-white;
          font-family: $worksans-medium;
          text-align: center;
        }
        figure {
          background: #fff;
          border: 2px solid #fff;
          border-radius: 50px;
          margin-bottom: 5px;
          max-height: 48px;
          min-height: 48px;
          overflow: hidden;
          width: 48px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            height: 100%;
            object-fit: fill;
            padding: 0;
            width: 100%;
          }
        }

        button.outlinebutton {
          border: 2px solid $white !important;
          color: $text-white !important;
          @include outlinebutton;
          &:hover,
          &:focus,
          &:active {
            border: 2px solid $white !important;
            color: $text-white !important;
          }
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .mainpage {
    position: relative;
    height: 100%;
    padding: 0 30px;
    .content {
      position: relative;
      height: 90vh;
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      margin: auto;
      flex-direction: column;
      h1 {
        color: $green !important;
        @include heading1;
        // font-size: 52.2px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        font-family: $worksans-bold;
        margin-bottom: 15px;
      }
      figure {
        // width: 371px;
        img {
          // width: 371px;
        }

        // margin-bottom: 100px;
      }
      @media (max-width: 767px) {
        figure {
          width: 300px;
          img {
            width: 300px;
          }

          // margin-bottom: 100px;
        }
      }

      button.fillbutton {
        @include fillbutton;
        font-weight: 800;
        font-size: 17.6px;
        background: $orange;
        text-transform: uppercase;
        color: $text-black;
        font-family: $worksans-bold;
        letter-spacing: -0.7px;
        width: 365px;
        padding-top: 11px;
        &:hover {
          background: $orange !important;
        }
      }
    }
  }

  .welcomePage {
    position: relative;
    // height: 100vh;
    padding: 0 30px;
    .content {
      position: relative;
      height: 91vh;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 414px;

      #welcometext {
        position: relative;
        @include heading1;
      }

      #welcometext:after {
        content: "Welcome!";
        position: absolute;
        top: -42px;
        -webkit-animation: spin 8s ease infinite; /* Include -webkit- prefix */
        animation: spin 8s linear infinite;
      }

      @keyframes spin {
        0% {
          visibility: hidden;
        }
        9% {
          visibility: visible;
          content: "Welcome!";
        }
        18% {
          visibility: hidden;
        }
        27% {
          visibility: visible;
          content: "Let's go!";
        }
        36% {
          visibility: hidden;
        }
        45% {
          visibility: visible;
          content: "Can't Waitttt";
        }
        54% {
          visibility: hidden;
        }
        63% {
          visibility: visible;
          content: "Get Excited!!";
        }
        72% {
          visibility: hidden;
        }
        81% {
          visibility: visible;
          content: "YASSSSSS";
        }
        90% {
          visibility: hidden;
        }
        100% {
          visibility: visible;
          content: "Get packin'!";
        }
      }
    }

    .welcomebg {
      position: fixed;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
      background-image: url(https://d2rweuuav95dhv.cloudfront.net/images/welcome-bg.gif);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      left: 0;
      // filter: blur(11px);
      // .overlay {
      //    background-color: rgba(0,0,0,0.2);
      //    position: absolute;
      //    height: 100vh;
      //    width: 100%;
      // }
      // video {
      //    position: absolute;
      //    height: 100vh;
      //    width: 100%;
      //    object-fit: cover;
      // }
    }
    .react-datepicker-wrapper {
      width: 100%;
      input.input-text {
        @include input-text;
        width: 100%;
        &:focus-visible {
          outline: none;
          padding: 0.39rem 15px;
        }
      }
      button {
        &::after {
          background-color: $white;
          color: $black;
          border-radius: 50%;
          height: 16px;
          display: flex !important;
          align-items: center;
          justify-content: center;
          width: 16px;
          padding: 9px 10px 11px;
          text-align: center;
          content: "×";
        }
      }
    }
    .contentFooter {
      position: absolute;
      bottom: 0;
      width: auto;
      text-align: center;
      left: 50%;
      transform: translate(-50%, -20px);
    }

    &::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      background: none;
      height: 100vh;
      width: 100%;
      backdrop-filter: blur(8px); /* Apply blur effect to the pseudo-element */
      -webkit-backdrop-filter: blur(8px);
      z-index: 0; /* Ensure the pseudo-element is behind the container */
    }

    .welcomebgvideo {
      display: none;
      position: fixed;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0px;
      z-index: -1;
      video {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .welcomebgvideomobile {
      display: block;
      position: fixed;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0px;
      z-index: -1;
      video {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  // inner pages

  .checkedinpage {
    height: 100vh;
    padding: 0 10px 10px;
    // background: $innerbg;

    .card-section {
      width: 414px;
      .cardYellow {
        background: $yellow;
        border-radius: 18px;
        padding: 8px 8px 20px;
        margin-bottom: 5px;
        figure {
          width: 100%;
          // max-height: 221.66px;
          // min-height: 221.66px;
          // overflow: hidden;
          border-radius: 11px;
          margin-bottom: 0;
          img {
            max-width: 100%;
            // max-height: 221.66px;
            // min-height: 221.66px;
            border-radius: 11px;
            object-fit: fill;
          }
        }

        .content {
          display: flex;
          justify-content: space-between;
          padding: 15px 14px 5px;
          .checkedinDate {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 50%;

            &:first-child {
              position: relative;
              width: 50%;
              padding-right: 30px;
              &::after {
                position: absolute;
                content: "";
                width: 1px;
                height: 24px;
                background: rgba(0, 0, 0, 0.08);
                right: 10px;
                top: 10px;
              }
            }
            &:last-child {
              padding-left: 30px;
            }
          }
        }
      }
      .cardOrange {
        background: $orange;
        border-radius: 18px;
        padding: 8px 8px 8px;
        .content {
          display: flex;
          flex-direction: column;
          padding: 5px 14px 5px;
          .checkedinDate {
            display: flex;
            flex-direction: column;

            label {
              @include label2;
            }
          }

          .react-tel-input {
            font-family: $worksans-regular;
            position: relative;
            input {
              @include input-text2;
              width: 100%;
              padding-left: 62px;
            }
            .flag-dropdown {
              background: transparent;
              border: none;

              .selected-flag {
                background: transparent;
              }

              &::after {
                position: absolute;
                width: 1px;
                height: 20px;
                background: rgba(0, 0, 0, 0.1);
                content: "";
                top: 15px;
                right: -12px;
              }
            }
            .country-list {
              width: 372px;
              border-radius: 12px;
            }
          }

          p {
            margin: 15px 0 5px;
          }
          form {
            label {
              margin-bottom: 5px;
            }
          }
        }
        hr {
          margin: 15px 0 12px;
        }
      }
    }
  }

  .checkinginpage {
    height: auto;
    padding: 0 10px 10px;
    // background: $innerbg;

    .card-section {
      width: 414px;
      .accrodianBlock {
        .cardYellow {
          background: $yellow;
          border-radius: 18px;
          padding: 17px 0 15px;
          margin-bottom: 5px;
          border: none;

          .accordion-header {
            button {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: -2px;
              }
            }
            button.collapsed {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              display: flex;
              align-items: baseline;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: 0px;
                position: relative;
                left: 0;
                top: -2px;
                width: 17px;
              }
            }
            h2 {
              margin-top: 0px;
              font-family: $worksans-medium;
              letter-spacing: -0.2px;
              display: flex;
              align-items: center;
              span {
                margin-left: 5px;
              }
            }
          }
          .accordion-body {
            padding-top: 15px;
            padding-bottom: 7px;
            .swiperSliderone {
              .swiper {
                height: 210px;
                .swiper-slide {
                  .form-check {
                    position: relative;
                    .form-check-input {
                      width: 100%;
                      height: 180px;
                      background: none;
                      // border-radius: 14px;
                      box-shadow: none;
                      border: none;
                      margin-top: 0;
                      &:hover,
                      &:focus,
                      &:active {
                        box-shadow: none;
                        border: none;
                        background: none;
                      }

                      &:checked[type="checkbox"] {
                        background: none;
                        &:hover,
                        &:focus,
                        &:active {
                          box-shadow: none;
                          border: none;
                          background: none;
                        }
                      }
                    }

                    .form-check-input:checked[type="checkbox"] + label {
                      position: absolute;
                      top: 0;
                      font-size: $font-size-18;
                      font-family: $worksans-medium;
                      font-weight: 500;
                      color: $text-black;
                      left: 0;
                      width: 100%;
                      height: 180px;
                      border-radius: 14px;
                      background: rgba(255, 255, 255, 1);
                      padding: 65px 14px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      line-height: 21px;
                      letter-spacing: -0.2px;
                      opacity: 1;
                    }
                    .form-check-input[type="checkbox"] + label {
                      position: absolute;
                      top: 0;
                      font-size: $font-size-18;
                      font-family: $worksans-medium;
                      font-weight: 500;
                      left: 0;
                      width: 100%;
                      height: 180px;
                      border-radius: 14px;
                      background: rgba(30, 28, 34, 0.1);
                      padding: 65px 14px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      line-height: 21px;
                      opacity: 1;
                      letter-spacing: -0.2px;
                      opacity: 0.5;
                    }
                    label {
                      color: $text-label-checkbox;
                    }
                  }
                }
                .swiper-slide.swiper-slide-active {
                  .form-check {
                    position: relative;
                    .form-check-input {
                      width: 100%;
                      height: 180px;
                      background: none;
                      // border-radius: 14px;
                      box-shadow: none;
                      border: none;
                      margin-top: 0;
                      &:hover,
                      &:focus,
                      &:active {
                        box-shadow: none;
                        border: none;
                        background: none;
                      }

                      &:checked[type="checkbox"] {
                        background: none;
                        &:hover,
                        &:focus,
                        &:active {
                          box-shadow: none;
                          border: none;
                          background: none;
                        }
                      }
                    }

                    .form-check-input:checked[type="checkbox"] + label {
                      position: absolute;
                      top: 0;
                      font-size: $font-size-18;
                      font-family: $worksans-medium;
                      font-weight: 500;
                      color: $text-black;
                      left: 0;
                      width: 100%;
                      height: 180px;
                      border-radius: 14px;
                      background: rgba(255, 255, 255, 1);
                      padding: 65px 14px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      line-height: 21px;
                      letter-spacing: -0.2px;
                      opacity: 1;
                    }
                    .form-check-input[type="checkbox"] + label {
                      position: absolute;
                      top: 0;
                      font-size: $font-size-18;
                      font-family: $worksans-medium;
                      font-weight: 500;
                      left: 0;
                      width: 100%;
                      height: 180px;
                      border-radius: 14px;
                      background: rgba(30, 28, 34, 0.1);
                      padding: 65px 14px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      line-height: 21px;
                      color: $text-black;
                      letter-spacing: -0.2px;
                      opacity: 1;
                    }
                  }
                }
                .swiper-pagination {
                  bottom: 0;
                  text-align: left;
                  font-family: $worksans-semibold;
                  font-weight: 500;
                  font-size: $font-size-12;
                  color: $text-black;
                  opacity: 50%;
                  letter-spacing: -1.3px;
                }

                .swiper-slide.autowidthchange {
                  width: auto !important;

                  .id_card_block.image-uploaded {
                    position: relative;
                    overflow: hidden;
                    display: inline-block;
                    width: auto;
                    height: 133px;
                    .id_card_uploaded {
                      width: auto;
                      figure {
                        width: auto;
                        img {
                          width: auto;
                        }
                      }
                    }
                  }
                  .id_card_block_selfie.image-uploaded {
                    position: relative;
                    overflow: hidden;
                    display: inline-block;
                    width: auto;
                    height: 133px;
                    .id_card_uploaded {
                      width: auto;
                      figure {
                        width: auto;
                        img {
                          width: auto;
                        }
                      }
                    }
                  }
                }
              }
              &.houseRulesSwiperLink {
                .swiper {
                  .swiper-slide {
                    .form-check {
                      .form-check-input[type=checkbox] + label {
                        padding: 15px 14px;
                      }
                    }
                  }
                }
              }
            }
            .swiperSliderone .swiper .swiper-slide-active {
              .id_card_uploaded {
                background: rgba(30, 28, 34, 0.1);
                border: none;
              }
            }

            button {
              div {
                text-transform: lowercase;
                &::first-letter {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
        .cardOrange {
          background: $orange;
          border-radius: 18px;
          padding: 17px 0 15px;
          margin-bottom: 5px;
          border: none;

          .accordion-header {
            button {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: -2px;
              }
            }
            button.collapsed {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              display: flex;
              align-items: baseline;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: 0px;
                position: relative;
                left: 0;
                top: -2px;
                width: 17px;
              }
            }
            h2 {
              margin-top: 0px;
              font-family: $worksans-medium;
              letter-spacing: -0.2px;
              display: flex;
              align-items: center;
              span {
                margin-left: 5px;
              }
            }
          }
          .accordion-body {
            padding-top: 15px;
            padding-bottom: 7px;
            .readagreement {
              max-height: 233px;
              overflow-y: auto;
              margin-bottom: 20px;
              padding-right: 10px;
            }
            button {
              div {
                text-transform: lowercase;
                &::first-letter {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
        .cardBlue {
          background: $blue;
          border-radius: 18px;
          padding: 17px 0 15px;
          margin-bottom: 5px;
          border: none;

          .accordion-header {
            button {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: -2px;
              }
            }
            button.collapsed {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              display: flex;
              align-items: baseline;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: 0px;
                position: relative;
                left: 0;
                top: -2px;
                width: 17px;
              }
            }
            h2 {
              margin-top: 0px;
              font-family: $worksans-medium;
              letter-spacing: -0.2px;
              display: flex;
              align-items: center;
              span {
                margin-left: 5px;
              }
            }
          }
          .accordion-body {
            padding-top: 15px;
            padding-bottom: 7px;
            h5 {
              margin-bottom: 3px;
            }
            .sub-title {
              font-family: $worksans-regular;
              font-weight: 400;
              color: $text-black;
              font-size: $font-size-13;
              display: block;
              opacity: 60%;
              line-height: 15.76px;
            }
            .sub-title-2 {
              font-family: $worksans-regular;
              font-weight: 400;
              color: $text-black;
              font-size: $font-size-13;
              display: block;
              opacity: 70%;
              line-height: 15.76px;
            }

            .swiperSliderone {
              .swiper {
                height: 160px;

                // .id_screen_upd {
                //    position: relative;
                //    overflow: hidden;
                //    display: inline-block;
                //    width: 100%;
                //    height: 133px;
                //  }

                //  .btn {
                //    border: 1px dashed rgba(0, 0, 0, 0.3);
                //    background-color: none;
                //    padding: 8px 20px;
                //    border-radius: 10px;
                //    font-size: 20px;
                //    font-weight: bold;
                //    width: 100%;
                //    height: 133px;
                //    p {
                //       @include paragraph;
                //       text-transform: lowercase;
                //          &::first-letter {
                //             text-transform: uppercase;
                //          }

                //       span {
                //          text-transform: uppercase;
                //       }
                //    }
                //  }

                //  .id_screen_upd input[type=file] {
                //    font-size: 100px;
                //    position: absolute;
                //    left: 0;
                //    top: 0;
                //    opacity: 0;
                //    width: 100%;
                //  }

                //  .swiper-slide.swiper-slide-active {

                //    .btn {
                //       border: none;
                //       background-color: rgba(30, 28, 34, 0.1);
                //       padding: 8px 20px;
                //       border-radius: 10px;
                //       font-size: 20px;
                //       font-weight: bold;
                //       width: 100%;
                //       height: 133px;
                //       p {
                //          @include paragraph;
                //          text-transform: lowercase;
                //             &::first-letter {
                //                text-transform: uppercase;
                //             }

                //          span {
                //             text-transform: uppercase;
                //          }
                //       }
                //     }

                //  }

                .id_card_block {
                  position: relative;
                  overflow: hidden;
                  display: inline-block;
                  width: 100%;
                  height: 133px;
                }

                .id_card_uploaded {
                  border: 1px dashed rgba(0, 0, 0, 0.3);
                  background: none;
                  padding: 0;
                  border-radius: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  width: 100%;
                  height: 133px;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: center;
                  text-align: center;
                  overflow: hidden;
                  figure {
                    height: 133px;
                    width: 100%;
                    margin: 0;
                    position: relative;

                    img {
                      height: 133px;
                      width: 100%;
                      object-fit: contain;
                    }
                    span.deletePreview {
                      position: absolute;
                      top: 1px;
                      right: 5px;
                      padding: 0;
                      background: none;
                      cursor: pointer;
                      img {
                        width: 28px;
                        height: 28px;
                      }
                    }
                  }
                  p.id_label {
                    font-size: $font-size-16;
                    color: $text-para-2;
                    margin-top: 5px;
                    @include paragraph;

                    text-transform: lowercase;
                    &::first-letter {
                      text-transform: uppercase;
                    }

                    span {
                      text-transform: uppercase;
                    }
                  }
                }

                .id_card_block_selfie {
                  position: relative;
                  overflow: hidden;
                  display: inline-block;
                  width: 100px;
                  height: 133px;
                }

                .id_card_uploaded_selfie {
                  border: 1px dashed rgba(0, 0, 0, 0.3);
                  background: none;
                  padding: 0;
                  border-radius: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  width: 100px;
                  height: 133px;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: center;
                  text-align: center;
                  overflow: hidden;
                  figure {
                    height: 133px;
                    width: 100%;
                    margin: 0;
                    position: relative;

                    img {
                      height: 133px;
                      width: 100%;
                      object-fit: contain;
                    }

                    span.deletePreview {
                      position: absolute;
                      top: 1px;
                      right: 5px;
                      padding: 0;
                      background: none;
                      cursor: pointer;
                      img {
                        width: 28px;
                        height: 28px;
                      }
                    }
                  }
                }

                .id_card {
                  border: 1px dashed rgba(0, 0, 0, 0.3);
                  background: none;
                  padding: 8px 20px;
                  border-radius: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  width: 100%;
                  height: 133px;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: center;
                  text-align: center;
                  img {
                    width: 32px;
                  }

                  p.id_label {
                    font-size: $font-size-16;
                    color: $text-para-2;
                    margin-top: 5px;
                    @include paragraph;

                    text-transform: lowercase;
                    &::first-letter {
                      text-transform: uppercase;
                    }

                    span {
                      text-transform: uppercase;
                    }
                  }
                }

                .id_card_selfie {
                  border: 1px dashed rgba(0, 0, 0, 0.3);
                  background: none;
                  padding: 15px 20px 0;
                  border-radius: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  width: 100px;
                  height: 133px;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: center;
                  text-align: center;
                  img {
                    width: 32px;
                  }

                  p.id_label {
                    font-size: $font-size-16;
                    color: $text-para-2;
                    margin-top: 5px;
                    @include paragraph;

                    text-transform: lowercase;
                    &::first-letter {
                      text-transform: uppercase;
                    }

                    span {
                      text-transform: uppercase;
                    }
                  }
                }

                .swiper-slide.swiper-slide-active {
                  .id_card {
                    border: none;
                    background-color: rgba(30, 28, 34, 0.1);
                    padding: 8px 20px;
                    border-radius: 10px;
                    font-size: 20px;
                    font-weight: bold;
                    width: 100%;
                    height: 133px;
                    img {
                      width: 32px;
                    }
                    p {
                      @include paragraph;
                      text-transform: lowercase;
                      &::first-letter {
                        text-transform: uppercase;
                      }

                      span {
                        text-transform: uppercase;
                      }
                    }
                  }

                  .id_card_selfie {
                    border: none;
                    background-color: rgba(30, 28, 34, 0.1);
                    padding: 15px 20px 0;
                    border-radius: 10px;
                    font-size: 20px;
                    font-weight: bold;
                    width: 100px;
                    height: 133px;
                    img {
                      width: 32px;
                    }
                    p {
                      @include paragraph;
                      text-transform: lowercase;
                      &::first-letter {
                        text-transform: uppercase;
                      }

                      span {
                        text-transform: uppercase;
                      }
                    }
                    p.id_label {
                      font-size: $font-size-16 !important;
                      color: $text-para-2 !important;
                      margin-top: 5px;
                      @include paragraph;

                      text-transform: lowercase;
                      &::first-letter {
                        text-transform: uppercase;
                      }

                      span {
                        text-transform: uppercase;
                      }
                    }
                  }
                }

                .swiper-pagination {
                  bottom: 0;
                  text-align: left;
                  font-family: $worksans-semibold;
                  font-weight: 500;
                  font-size: $font-size-12;
                  color: $text-black;
                  opacity: 50%;
                  letter-spacing: -1.3px;
                }

                .swiper-slide.autowidthchange {
                  width: auto !important;

                  .id_card_block.image-uploaded {
                    position: relative;
                    overflow: hidden;
                    display: inline-block;
                    width: auto;
                    height: 133px;

                    .id_card_uploaded {
                      width: auto;
                      figure {
                        width: auto;
                        img {
                          width: auto;
                        }
                      }
                    }
                  }
                  .id_card_block_selfie.image-uploaded {
                    position: relative;
                    overflow: hidden;
                    display: inline-block;
                    width: auto;
                    height: 133px;
                    .id_card_uploaded {
                      width: auto;
                      figure {
                        width: auto;
                        img {
                          width: auto;
                        }
                      }
                    }
                  }
                }
              }
            }
            .swiperSliderone .swiper .swiper-slide-active {
              .id_card_uploaded {
                background: rgba(30, 28, 34, 0.1);
                border: none;
              }
            }

            button {
              div {
                text-transform: lowercase;
                &::first-letter {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
        .cardGreen {
          background: $green;
          border-radius: 18px;
          padding: 17px 0 15px;
          margin-bottom: 5px;
          border: none;

          .accordion-header {
            button {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: -2px;
              }
            }
            button.collapsed {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              display: flex;
              align-items: baseline;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: 0px;
                position: relative;
                left: 0;
                top: -2px;
                width: 17px;
              }
            }

            h2 {
              margin-top: 0px;
              font-family: $worksans-medium;
              letter-spacing: -0.2px;
              display: flex;
              align-items: center;
              span {
                margin-left: 5px;
              }
            }
          }
          .accordion-body {
            padding-top: 15px;
            padding-bottom: 7px;
            .radiobox {
              .form-check-input:checked[type="radio"] {
                border: 6px solid $black;
                background: none;
              }
              .form-check-input[type="radio"] {
                border: 1px solid rgba(0, 0, 0, 0.3);
                background: none;

                &:focus,
                &:hover,
                &:active {
                  box-shadow: 0 0 0 0.25rem rgb(93 93 93 / 25%) !important;
                }
              }
              label {
                font-family: $worksans-medium;
                font-size: $font-size-16;
                color: $text-black;
                font-weight: 500;
                opacity: 1;
              }
            }

            button {
              div {
                text-transform: lowercase;
                &::first-letter {
                  text-transform: uppercase;
                }
              }
            }

            ul {
              padding-left: 15px;
              margin-bottom: 60px;
              li {
                @include paragraph2;
                margin-bottom: 7px;
                opacity: 70% !important;
                &::marker {
                  position: absolute;
                  font-size: 95%;
                }
              }
            }
          }
        }

        .accrodionCollapsed {
          pointer-events: none;
          button.collapsed {
            background: transparent;
            box-shadow: none;
            padding-bottom: 0;
            padding-top: 0;
            display: flex;
            align-items: baseline;
            &::after {
              display: none;
            }

            img {
              margin-right: 7px;
              margin-top: 0px;
              position: relative;
              left: 0;
              top: 1px !important;
              width: 17px;
            }
          }
        }
      }
    }
  }

  .reservationnpage {
    height: 100%;
    padding: 0 10px;
    // background: $innerbg;

    .card-section {
      width: 414px;

      .cardGreen {
        background: $green;
        border-radius: 18px;
        padding: 8px 8px 20px;
        margin-bottom: 5px;

        .reserved {
          position: relative;
          .reservedDate {
            position: absolute;
            display: flex;
            justify-content: space-between;
            padding: 15px 14px 5px;
            width: 100%;
            z-index: 1;
            .checkedinDate {
              display: flex;
              flex-direction: column;
              position: relative;
              width: 50%;
              label,
              .dataLabel {
                color: rgba(255, 255, 255, 1);
              }
              &:first-child {
                position: relative;
                width: 50%;
                padding-right: 30px;
                &::after {
                  position: absolute;
                  content: "";
                  width: 1px;
                  height: 24px;
                  background: rgba(255, 255, 255, 0.08);
                  right: 10px;
                  top: 10px;
                }
              }
              &:last-child {
                padding-left: 30px;
              }
            }
          }
          figure {
            width: 100%;
            // max-height: 150px;
            // min-height: 150px;
            // max-height: 221.66px;
            // min-height: 221.66px;
            // overflow: hidden;
            border-radius: 11px;
            margin-bottom: 0;
            position: relative;
            overflow: hidden;
            img {
              max-width: 100%;
              // max-height: 150px;
              // min-height: 150px;
              // max-height: 221.66px;
              // min-height: 221.66px;
              border-radius: 11px;
              object-fit: cover;
            }

            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-image: linear-gradient(180deg, #00000099, transparent);
            }
          }
        }
        .content {
          display: flex;
          justify-content: space-between;
          padding: 15px 14px 5px;
          align-items: center;
          .checkedinDate {
            display: flex;
            flex-direction: column;
            position: relative;
            // width: 50%;
            width: 50px;
            text-align: right;
            label {
              line-height: 16.42px;
              color: $text-black;
              opacity: 80%;
            }
            img {
              width: 38px;
            }
            &:first-child {
              position: relative;
              // width: 50%;
              width: calc(100% - 50px);
              padding-right: 0px;
              text-align: left;
              &::after {
                position: absolute;
                content: "";
                width: 1px;
                height: 24px;
                background: rgba(0, 0, 0, 0.08);
                right: 10px;
                top: 10px;
                display: none;
              }
            }
            &:last-child {
              padding-left: 10px;
            }
          }
        }
        // video {
        //    width: 100%;
        //    height: 533px;
        //    border: none;
        // }

        video {
          height: auto;
          width: 100%;
          // aspect-ratio: 9 / 16;
          // aspect-ratio: 1.17 / 1;
          border: 0;
          border-radius: 11px;
        }

        // iframe {
        //    height: auto;
        //    width: 100%;
        //    // aspect-ratio: 9 / 16;
        //    aspect-ratio: 1.17 / 1;
        //    border: 0;
        //    border-radius: 11px;
        // }
      }
      .cardBlue {
        background: $blue;
        border-radius: 18px;
        padding: 8px 20px 20px;
        margin-bottom: 5px;
        h2 {
          margin: 10px 0;
          font-family: $worksans-medium;
        }
        figure {
          width: 100%;
          // max-height: 221.66px;
          // min-height: 221.66px;
          // overflow: hidden;
          border-radius: 11px;
          margin-bottom: 20px;
          img {
            max-width: 100%;
            // max-height: 221.66px;
            // min-height: 221.66px;
            border-radius: 11px;
            object-fit: fill;
          }
        }
        p {
          color: $text-black;
          opacity: 80%;
          line-height: 16.42px;
        }
        .content {
          display: flex;
          justify-content: space-between;
          padding: 5px 0px 5px;
          width: 100%;
          .checkedinDate {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 50%;

            &:first-child {
              position: relative;
              width: 50%;
              padding-right: 30px;
              &::after {
                position: absolute;
                content: "";
                width: 1px;
                height: 24px;
                background: rgba(0, 0, 0, 0.08);
                right: 10px;
                top: 10px;
              }
            }
            &:last-child {
              padding-left: 30px;
            }

            span.dataLabel {
              white-space: break-spaces;
              word-wrap: break-word;
            }
          }
        }
        // video {
        //    width: 100%;
        //    height: 533px;
        //    border: none;
        // }

        video {
          height: auto;
          width: 100%;
          // aspect-ratio: 9 / 16;
          // aspect-ratio: 1.17 / 1;
          border: 0;
          border-radius: 11px;
        }

        // iframe {
        //    height: auto;
        //    width: 100%;
        //    // aspect-ratio: 9 / 16;
        //    aspect-ratio: 1.17 / 1;
        //    border: 0;
        //    border-radius: 11px;
        // }
      }
      .cardYellow {
        background: $yellow;
        border-radius: 18px;
        padding: 8px 20px 20px;
        margin-bottom: 5px;
        h2 {
          margin: 10px 0;
          font-family: $worksans-medium;
        }
        p {
          color: $text-black;
          opacity: 80%;
          line-height: 16.42px;
        }

        button {
          text-transform: lowercase;
          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
      .cardGreen2 {
        background: $green;
        border-radius: 18px;
        padding: 8px 20px 20px;
        margin-bottom: 5px;
        h2 {
          margin: 10px 0;
          font-family: $worksans-medium;
        }
        p {
          color: $text-black;
          opacity: 80%;
          line-height: 16.42px;
        }
        .localMap {
          background: $white;
          padding: 10px;
          border-radius: 18px;

          figure {
            width: 100%;
            max-height: 145px;
            min-height: 145px;
            overflow: hidden;
            border-radius: 6px;
            margin-bottom: 0;
            img {
              width: 100%;
              max-height: 145px;
              min-height: 145px;
              border-radius: 6px;
              object-fit: cover;
            }
          }
          .content {
            display: flex;
            justify-content: space-between;
            padding: 15px 0px 5px;
            align-items: center;
            flex-direction: column;
            .dataLabel {
              font-weight: 700;
            }
            .card-subtitle {
              font-size: $font-size-12;
              color: $text-para;
              font-family: $worksans-regular;
              display: block;
              width: 100%;
            }
            button {
              div {
                text-transform: lowercase;
                &::first-letter {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
      }
      .cardPink{
         background: $pink;
       }
      .cardOrange {
        background: $orange;
        border-radius: 18px;
        padding: 8px 20px 20px;
        margin-bottom: 5px;
        h2 {
          margin: 10px 0;
          font-family: $worksans-medium;
        }
        p {
          color: $text-black;
          opacity: 80%;
          line-height: 16.42px;
        }

        button {
          text-transform: lowercase;
          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
      .cardBlank {
        background: none;
        padding: 20px 15px 40px;
        margin-bottom: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
          margin: 10px 0 25px;
          color: $text-white;
          font-family: $worksans-medium;
          text-align: center;
        }
        figure {
          background: #fff;
          border: 2px solid #fff;
          border-radius: 50px;
          margin-bottom: 5px;
          max-height: 48px;
          min-height: 48px;
          overflow: hidden;
          width: 48px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            height: 100%;
            object-fit: fill;
            padding: 0;
            width: 100%;
          }
        }

        button.outlinebutton {
          border: 2px solid $white !important;
          color: $text-white !important;
          @include outlinebutton;
          &:hover,
          &:focus,
          &:active {
            border: 2px solid $white !important;
            color: $text-white !important;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .mainpage {
    position: relative;
    height: 100%;
    padding: 0 15px;
    .content {
      position: relative;
      height: 90vh;
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      margin: auto;
      flex-direction: column;

      h1 {
        color: $green !important;
        @include heading1;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        font-family: $worksans-bold;
        margin-bottom: 15px;
        letter-spacing: -2.5px;
      }
      figure {
        width: 371px;
        img {
          width: 371px;
        }
        // margin-bottom: 100px;
      }
      @media (max-width: 767px) {
        figure {
          width: 300px;
          img {
            width: 300px;
          }

          // margin-bottom: 100px;
        }
      }

      button.fillbutton {
        @include fillbutton;
        font-size: 17.6px;
        font-weight: 800;
        background: $orange;
        text-transform: uppercase;
        color: $text-black;
        font-family: $worksans-bold;
        letter-spacing: -0.7px;
        width: 260px;
        padding-top: 6px;
        &:hover {
          background: $orange !important;
        }
      }
      .for-host-sec{
        margin-top: 15px;
      }
    }
    &.cleaning_section {
      .content {
        figure {
          width: 150px;
        }
        .cleaning_address {
          width: auto;
        }
        button {
          font-size: 14px;
        }
      }
    }
  }

  .welcomePage {
    position: relative;
    // height: 100vh;
    padding: 0 30px;
    .content {
      position: relative;
      height: 91vh;
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;

      #welcometext {
        position: relative;
        @include heading1;
      }

      #welcometext:after {
        content: "Welcome!";
        position: absolute;
        top: -42px;
        -webkit-animation: spin 8s ease infinite; /* Include -webkit- prefix */
        animation: spin 8s linear infinite;
      }

      @keyframes spin {
        0% {
          visibility: hidden;
        }
        9% {
          visibility: visible;
          content: "Welcome!";
        }
        18% {
          visibility: hidden;
        }
        27% {
          visibility: visible;
          content: "Let's go!";
        }
        36% {
          visibility: hidden;
        }
        45% {
          visibility: visible;
          content: "Can't Waitttt";
        }
        54% {
          visibility: hidden;
        }
        63% {
          visibility: visible;
          content: "Get Excited!!";
        }
        72% {
          visibility: hidden;
        }
        81% {
          visibility: visible;
          content: "YASSSSSS";
        }
        90% {
          visibility: hidden;
        }
        100% {
          visibility: visible;
          content: "Get packin'!";
        }
      }
    }

    .welcomebg {
      position: fixed;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
      background-image: url(https://d2rweuuav95dhv.cloudfront.net/images/welcome-bg.gif);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      left: 0;
      // filter: blur(11px);

      // video {
      //    position: absolute;
      //    height: 100vh;
      //    width: 100%;
      //    object-fit: cover;
      // }
    }
    .react-datepicker-wrapper {
      width: 100%;
      input.input-text {
        @include input-text;
        width: 100%;
        &:focus-visible {
          outline: none;
          padding: 0.39rem 15px;
        }
      }
      button {
        &::after {
          background-color: $white;
          color: $black;
          border-radius: 50%;
          height: 16px;
          display: flex !important;
          align-items: center;
          justify-content: center;
          width: 16px;
          padding: 9px 10px 11px;
          text-align: center;
          content: "×";
        }
      }
    }
    .contentFooter {
      position: absolute;
      bottom: 0;
      width: auto;
      text-align: center;
      left: 50%;
      transform: translate(-50%, -20px);
    }
    &::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      background: none;
      height: 100%;
      width: 100%;
      backdrop-filter: blur(8px); /* Apply blur effect to the pseudo-element */
      -webkit-backdrop-filter: blur(8px);
      z-index: 0; /* Ensure the pseudo-element is behind the container */
    }

    .welcomebgvideo {
      display: none;
      position: fixed;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0px;
      z-index: -1;
      video {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .welcomebgvideomobile {
      display: block;
      position: fixed;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0px;
      z-index: -1;
      video {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  // inner pages

  .checkedinpage {
    height: 100%;
    padding: 0 10px 10px;
    // background: $innerbg;

    .card-section {
      width: auto;
      .cardYellow {
        background: $yellow;
        border-radius: 18px;
        padding: 8px 8px 20px;
        margin-bottom: 5px;
        figure {
          width: 100%;
          // max-height: 221.66px;
          // min-height: 221.66px;
          // overflow: hidden;
          border-radius: 11px;
          margin-bottom: 0;
          img {
            max-width: 100%;
            // max-height: 221.66px;
            // min-height: 221.66px;
            border-radius: 11px;
            object-fit: fill;
          }
        }

        .content {
          display: flex;
          justify-content: space-between;
          padding: 15px 14px 5px;
          .checkedinDate {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 50%;

            &:first-child {
              position: relative;
              width: 50%;
              padding-right: 10px;
              &::after {
                position: absolute;
                content: "";
                width: 1px;
                height: 24px;
                background: rgba(0, 0, 0, 0.08);
                right: 10px;
                top: 10px;
              }
            }
            &:last-child {
              padding-left: 10px;
            }
          }
        }
      }
      .cardOrange {
        background: $orange;
        border-radius: 18px;
        padding: 8px 8px 8px;
        .content {
          display: flex;
          flex-direction: column;
          padding: 5px 14px 8px;
          .checkedinDate {
            display: flex;
            flex-direction: column;

            label {
              @include label2;
            }
          }

          .react-tel-input {
            font-family: $worksans-regular;
            position: relative;
            input {
              @include input-text2;
              width: 100%;
              padding-left: 62px;
            }
            .flag-dropdown {
              background: transparent;
              border: none;

              .selected-flag {
                background: transparent;
              }

              &::after {
                position: absolute;
                width: 1px;
                height: 20px;
                background: rgba(0, 0, 0, 0.1);
                content: "";
                top: 15px;
                right: -12px;
              }
            }
            .country-list {
              width: 350px;
              border-radius: 12px;
            }
          }

          p {
            margin: 15px 0 5px;
          }
          form {
            label {
              margin-bottom: 5px;
            }
          }
        }
        hr {
          margin: 15px 0 12px;
        }
      }
    }
  }

  .checkinginpage {
    height: auto;
    padding: 0 10px 10px;
    // background: $innerbg;

    .card-section {
      width: auto;
      .accrodianBlock {
        .cardYellow {
          background: $yellow;
          border-radius: 18px;
          padding: 17px 0 17px;
          margin-bottom: 5px;
          border: none;

          .accordion-header {
            button {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: -2px;
              }
            }
            button.collapsed {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              display: flex;
              align-items: baseline;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: 0px;
                position: relative;
                left: 0;
                top: -3px;
              }
            }
            h2 {
              margin-top: 0px;
              font-family: $worksans-medium;
              letter-spacing: -0.2px;
              display: flex;
              align-items: center;
              span {
                margin-left: 5px;
              }
            }
          }
          .accordion-body {
            padding-top: 15px;
            padding-bottom: 7px;
            .swiperSliderone {
              .swiper {
                height: 210px;
                .swiper-slide {
                  .form-check {
                    position: relative;
                    .form-check-input {
                      width: 100%;
                      height: 180px;
                      background: none;
                      // border-radius: 14px;
                      box-shadow: none;
                      border: none;
                      margin-top: 0;
                      &:hover,
                      &:focus,
                      &:active {
                        box-shadow: none;
                        border: none;
                        background: none;
                      }

                      &:checked[type="checkbox"] {
                        background: none;
                        &:hover,
                        &:focus,
                        &:active {
                          box-shadow: none;
                          border: none;
                          background: none;
                        }
                      }
                    }

                    .form-check-input:checked[type="checkbox"] + label {
                      position: absolute;
                      top: 0;
                      font-size: $font-size-18;
                      font-family: $worksans-medium;
                      font-weight: 500;
                      color: $text-black;
                      left: 0;
                      width: 100%;
                      height: 180px;
                      border-radius: 14px;
                      background: rgba(255, 255, 255, 1);
                      padding: 65px 14px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      line-height: 21px;
                      opacity: 1;
                    }
                    .form-check-input[type="checkbox"] + label {
                      position: absolute;
                      top: 0;
                      font-size: $font-size-18;
                      font-family: $worksans-medium;
                      font-weight: 500;
                      left: 0;
                      width: 100%;
                      height: 180px;
                      border-radius: 14px;
                      background: rgba(30, 28, 34, 0.1);
                      padding: 65px 14px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      line-height: 21px;
                      opacity: 0.5;
                    }
                    label {
                      color: $text-label-checkbox;
                    }
                  }
                }
                .swiper-slide.swiper-slide-active {
                  .form-check {
                    position: relative;
                    .form-check-input {
                      width: 100%;
                      height: 180px;
                      background: none;
                      // border-radius: 14px;
                      box-shadow: none;
                      border: none;
                      margin-top: 0;
                      &:hover,
                      &:focus,
                      &:active {
                        box-shadow: none;
                        border: none;
                        background: none;
                      }

                      &:checked[type="checkbox"] {
                        background: none;
                        &:hover,
                        &:focus,
                        &:active {
                          box-shadow: none;
                          border: none;
                          background: none;
                        }
                      }
                    }

                    .form-check-input:checked[type="checkbox"] + label {
                      position: absolute;
                      top: 0;
                      font-size: $font-size-18;
                      font-family: $worksans-medium;
                      font-weight: 500;
                      color: $text-black;
                      left: 0;
                      width: 100%;
                      height: 180px;
                      border-radius: 14px;
                      background: rgba(255, 255, 255, 1);
                      padding: 65px 14px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      line-height: 21px;
                      letter-spacing: -0.2px;
                      opacity: 1;
                    }
                    .form-check-input[type="checkbox"] + label {
                      position: absolute;
                      top: 0;
                      font-size: $font-size-18;
                      font-family: $worksans-medium;
                      font-weight: 500;
                      left: 0;
                      width: 100%;
                      height: 180px;
                      border-radius: 14px;
                      background: rgba(30, 28, 34, 0.1);
                      padding: 65px 14px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      line-height: 21px;
                      color: $text-black;
                      letter-spacing: -0.2px;
                      opacity: 1;
                    }
                  }
                }
                .swiper-pagination {
                  bottom: 0;
                  text-align: left;
                  font-family: $worksans-semibold;
                  font-weight: 500;
                  font-size: $font-size-12;
                  color: $text-black;
                  opacity: 50%;
                  letter-spacing: -1.3px;
                }

                .swiper-slide.autowidthchange {
                  width: auto !important;

                  .id_card_block.image-uploaded {
                    position: relative;
                    overflow: hidden;
                    display: inline-block;
                    width: auto;
                    height: 133px;
                    .id_card_uploaded {
                      width: auto;
                      figure {
                        width: auto;
                        img {
                          width: auto;
                        }
                      }
                    }
                  }
                  .id_card_block_selfie.image-uploaded {
                    position: relative;
                    overflow: hidden;
                    display: inline-block;
                    width: auto;
                    height: 133px;
                    .id_card_uploaded {
                      width: auto;
                    }
                  }
                }
              }
              &.houseRulesSwiperLink {
                .swiper {
                  .swiper-slide {
                    .form-check {
                      .form-check-input[type=checkbox] + label {
                        padding: 12px 10px;
                        height: 188px;
                      }
                    }
                  }
                }
              }
            }
            .swiperSliderone .swiper .swiper-slide-active {
              .id_card_uploaded {
                background: rgba(30, 28, 34, 0.1);
                border: none;
              }
            }

            button {
              div {
                text-transform: lowercase;
                &::first-letter {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
        .cardOrange {
          background: $orange;
          border-radius: 18px;
          padding: 17px 0 15px;
          margin-bottom: 5px;
          border: none;

          .accordion-header {
            button {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: -2px;
              }
            }
            button.collapsed {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              display: flex;
              align-items: baseline;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: 0px;
                position: relative;
                left: 0;
                top: -2px;
              }
            }
            h2 {
              margin-top: 0px;
              font-family: $worksans-medium;
              letter-spacing: -0.2px;
              display: flex;
              align-items: center;
              span {
                margin-left: 5px;
              }
            }
          }
          .accordion-body {
            padding-top: 15px;
            padding-bottom: 7px;
            .readagreement {
              max-height: 233px;
              overflow-y: auto;
              margin-bottom: 20px;
              padding-right: 10px;
              p {
                color: $text-black;
                opacity: 60%;
              }
            }
            button {
              div {
                text-transform: lowercase;
                &::first-letter {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
        .cardBlue {
          background: $blue;
          border-radius: 18px;
          padding: 17px 0 15px;
          margin-bottom: 5px;
          border: none;

          .accordion-header {
            button {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: -2px;
              }
            }
            button.collapsed {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              display: flex;
              align-items: baseline;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: 0px;
                position: relative;
                left: 0;
                top: -2px;
              }
            }
            h2 {
              margin-top: 0px;
              font-family: $worksans-medium;
              letter-spacing: -0.2px;
              display: flex;
              align-items: center;
              span {
                margin-left: 5px;
              }
            }
          }
          .accordion-body {
            padding-top: 15px;
            padding-bottom: 7px;
            h5 {
              margin-bottom: 3px;
            }
            .sub-title {
              font-family: $worksans-regular;
              font-weight: 400;
              color: $text-black;
              font-size: $font-size-13;
              display: block;
              opacity: 60%;
              line-height: 15.76px;
            }
            .sub-title-2 {
              font-family: $worksans-regular;
              font-weight: 400;
              color: $text-black;
              font-size: $font-size-13;
              display: block;
              opacity: 70%;
              line-height: 15.76px;
            }

            .swiperSliderone {
              .swiper {
                height: 160px;

                // .id_screen_upd {
                //    position: relative;
                //    overflow: hidden;
                //    display: inline-block;
                //    width: 100%;
                //    height: 133px;
                //  }

                //  .btn {
                //    border: 1px dashed rgba(0, 0, 0, 0.3);
                //    background-color: none;
                //    padding: 8px 20px;
                //    border-radius: 10px;
                //    font-size: 20px;
                //    font-weight: bold;
                //    width: 100%;
                //    height: 133px;
                //    p {
                //       @include paragraph;
                //       text-transform: lowercase;
                //          &::first-letter {
                //             text-transform: uppercase;
                //          }

                //       span {
                //          text-transform: uppercase;
                //       }
                //    }
                //  }

                //  .id_screen_upd input[type=file] {
                //    font-size: 100px;
                //    position: absolute;
                //    left: 0;
                //    top: 0;
                //    opacity: 0;
                //    width: 100%;
                //  }

                //  .swiper-slide.swiper-slide-active {

                //    .btn {
                //       border: none;
                //       background-color: rgba(30, 28, 34, 0.1);
                //       padding: 8px 20px;
                //       border-radius: 10px;
                //       font-size: 20px;
                //       font-weight: bold;
                //       width: 100%;
                //       height: 133px;
                //       p {
                //          @include paragraph;
                //          text-transform: lowercase;
                //             &::first-letter {
                //                text-transform: uppercase;
                //             }

                //          span {
                //             text-transform: uppercase;
                //          }
                //       }
                //     }

                //  }

                .id_card_block {
                  position: relative;
                  overflow: hidden;
                  display: inline-block;
                  width: 100%;
                  height: 133px;
                }

                .id_card_uploaded {
                  border: 1px dashed rgba(0, 0, 0, 0.3);
                  background: none;
                  padding: 0;
                  border-radius: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  width: 100%;
                  height: 133px;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: center;
                  text-align: center;
                  overflow: hidden;
                  figure {
                    height: 133px;
                    width: 100%;
                    margin: 0;
                    position: relative;

                    img {
                      height: 133px;
                      width: 100%;
                      object-fit: contain;
                    }

                    span.deletePreview {
                      position: absolute;
                      top: 1px;
                      right: 5px;
                      padding: 0;
                      background: none;
                      cursor: pointer;
                      img {
                        width: 28px;
                        height: 28px;
                      }
                    }
                  }

                  p.id_label {
                    font-size: $font-size-16 !important;
                    color: $text-para-2 !important;
                    margin-top: 5px;
                    @include paragraph;

                    text-transform: lowercase;
                    &::first-letter {
                      text-transform: uppercase;
                    }

                    span {
                      text-transform: uppercase;
                    }
                  }
                }

                .id_card_block_selfie {
                  position: relative;
                  overflow: hidden;
                  display: inline-block;
                  width: 100px;
                  height: 133px;
                }

                .id_card_uploaded_selfie {
                  border: 1px dashed rgba(0, 0, 0, 0.3);
                  background: none;
                  padding: 0;
                  border-radius: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  width: 100px;
                  height: 133px;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: center;
                  text-align: center;
                  overflow: hidden;
                  figure {
                    height: 133px;
                    width: 100%;
                    margin: 0;
                    position: relative;

                    img {
                      height: 133px;
                      width: 100%;
                      object-fit: contain;
                    }

                    span.deletePreview {
                      position: absolute;
                      top: 1px;
                      right: 5px;
                      padding: 0;
                      background: none;
                      cursor: pointer;
                      img {
                        width: 28px;
                        height: 28px;
                      }
                    }
                  }

                  p.id_label {
                    font-size: $font-size-16 !important;
                    color: $text-para-2 !important;
                    margin-top: 5px;
                    @include paragraph;

                    text-transform: lowercase;
                    &::first-letter {
                      text-transform: uppercase;
                    }

                    span {
                      text-transform: uppercase;
                    }
                  }
                }

                .id_card {
                  border: 1px dashed rgba(0, 0, 0, 0.3);
                  background: none;
                  padding: 8px 20px;
                  border-radius: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  width: 100%;
                  height: 133px;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: center;
                  text-align: center;

                  img {
                    width: 32px;
                  }

                  p.id_label {
                    font-size: $font-size-16 !important;
                    color: $text-para-2 !important;
                    margin-top: 5px;
                    @include paragraph;

                    text-transform: lowercase;
                    &::first-letter {
                      text-transform: uppercase;
                    }

                    span {
                      text-transform: uppercase;
                    }
                  }
                }

                .id_card_selfie {
                  border: 1px dashed rgba(0, 0, 0, 0.3);
                  background: none;
                  padding: 15px 20px 0;
                  border-radius: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  width: 100px;
                  height: 133px;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: center;
                  text-align: center;

                  img {
                    width: 32px;
                  }
                }

                .swiper-slide.swiper-slide-active {
                  .id_card {
                    border: none;
                    background-color: rgba(30, 28, 34, 0.1);
                    padding: 8px 20px;
                    border-radius: 10px;
                    font-size: 20px;
                    font-weight: bold;
                    width: 100%;
                    height: 133px;
                    img {
                      width: 32px;
                    }
                    p {
                      @include paragraph;
                      text-transform: lowercase;
                      &::first-letter {
                        text-transform: uppercase;
                      }

                      span {
                        text-transform: uppercase;
                      }
                    }
                  }

                  .id_card_selfie {
                    border: none;
                    background-color: rgba(30, 28, 34, 0.1);
                    padding: 15px 20px 0;
                    border-radius: 10px;
                    font-size: 20px;
                    font-weight: bold;
                    width: 100px;
                    height: 133px;
                    img {
                      width: 32px;
                    }
                    p {
                      @include paragraph;
                      text-transform: lowercase;
                      &::first-letter {
                        text-transform: uppercase;
                      }

                      span {
                        text-transform: uppercase;
                      }
                    }
                  }
                }

                .swiper-pagination {
                  bottom: 0;
                  text-align: left;
                  font-family: $worksans-semibold;
                  font-weight: 500;
                  font-size: $font-size-12;
                  color: $text-black;
                  opacity: 50%;
                  letter-spacing: -1.3px;
                }

                .swiper-slide.autowidthchange {
                  width: auto !important;

                  .id_card_block.image-uploaded {
                    position: relative;
                    overflow: hidden;
                    display: inline-block;
                    width: auto;
                    height: 133px;
                    .id_card_uploaded {
                      width: auto;
                      figure {
                        width: auto;
                        img {
                          width: auto;
                        }
                      }
                    }
                  }
                  .id_card_block_selfie.image-uploaded {
                    position: relative;
                    overflow: hidden;
                    display: inline-block;
                    width: auto;
                    height: 133px;
                    .id_card_uploaded {
                      width: auto;
                      figure {
                        width: auto;
                        img {
                          width: auto;
                        }
                      }
                    }
                  }
                }
              }
            }
            .swiperSliderone .swiper .swiper-slide-active {
              .id_card_uploaded {
                background: rgba(30, 28, 34, 0.1);
                border: none;
              }
            }

            button {
              div {
                text-transform: lowercase;
                &::first-letter {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
        .cardGreen {
          background: $green;
          border-radius: 18px;
          padding: 17px 0 15px;
          margin-bottom: 5px;
          border: none;

          .accordion-header {
            button {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: -2px;
              }
            }
            button.collapsed {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              display: flex;
              align-items: baseline;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: 0px;
                position: relative;
                left: 0;
                top: -2px;
              }
            }
            h2 {
              margin-top: 0px;
              font-family: $worksans-medium;
              letter-spacing: -0.2px;
              display: flex;
              align-items: center;
              span {
                margin-left: 5px;
              }
            }
          }
          .accordion-body {
            padding-top: 15px;
            padding-bottom: 7px;
            .radiobox {
              .form-check-input:checked[type="radio"] {
                border: 6px solid $black;
                background: none;
              }
              .form-check-input[type="radio"] {
                border: 1px solid rgba(0, 0, 0, 0.3);
                background: none;

                &:focus,
                &:hover,
                &:active {
                  box-shadow: 0 0 0 0.25rem rgb(93 93 93 / 25%) !important;
                }
              }
              label {
                font-family: $worksans-medium;
                font-size: $font-size-16;
                color: $text-black;
                font-weight: 500;
                opacity: 1;
              }
            }

            button {
              div {
                text-transform: lowercase;
                &::first-letter {
                  text-transform: uppercase;
                }
              }
            }

            ul {
              padding-left: 15px;
              margin-bottom: 60px;
              li {
                @include paragraph2;
                margin-bottom: 7px;
                opacity: 70% !important;
                &::marker {
                  position: absolute;
                  font-size: 95%;
                }
              }
            }
          }
        }

        .accrodionCollapsed {
          pointer-events: none;
          button.collapsed {
            background: transparent;
            box-shadow: none;
            padding-bottom: 0;
            padding-top: 0;
            display: flex;
            align-items: center;
            &::after {
              display: none;
            }

            img {
              margin-right: 7px !important;
              margin-top: 0px !important;
              width: 17px;
              top: 1px !important;
            }
          }
        }
      }
    }
  }

  .reservationnpage {
    height: 100%;
    padding: 0 10px;
    // background: $innerbg;

    .card-section {
      width: auto;
      .cardGreen {
        background: $green;
        border-radius: 18px;
        padding: 8px 8px 20px;
        margin-bottom: 5px;

        .reserved {
          position: relative;
          .reservedDate {
            position: absolute;
            display: flex;
            justify-content: space-between;
            padding: 15px 5px 5px 14px;
            width: 100%;
            z-index: 1;
            .checkedinDate {
              display: flex;
              flex-direction: column;
              position: relative;
              width: 50%;
              label,
              .dataLabel {
                color: rgba(255, 255, 255, 1);
              }
              &:first-child {
                position: relative;
                width: 50%;
                padding-right: 15px;
                &::after {
                  position: absolute;
                  content: "";
                  width: 1px;
                  height: 24px;
                  background: rgba(255, 255, 255, 0.08);
                  right: 15px;
                  top: 10px;
                }
              }
              &:last-child {
                padding-left: 8px;
              }
            }
          }
          figure {
            width: 100%;
            // max-height: 150px;
            // min-height: 150px;
            // max-height: 221.66px;
            // min-height: 221.66px;
            // overflow: hidden;
            border-radius: 11px;
            margin-bottom: 0;
            position: relative;
            overflow: hidden;
            img {
              max-width: 100%;
              // max-height: 150px;
              // min-height: 150px;
              // max-height: 221.66px;
              // min-height: 221.66px;
              border-radius: 11px;
              object-fit: cover;
            }

            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-image: linear-gradient(180deg, #00000099, transparent);
            }
          }
        }
        .content {
          display: flex;
          justify-content: space-between;
          padding: 15px 14px 5px;
          align-items: center;
          .checkedinDate {
            display: flex;
            flex-direction: column;
            position: relative;
            // width: 50%;
            width: 50px;
            text-align: right;
            label {
              line-height: 16.42px;
              color: $text-black;
              opacity: 80%;
            }
            img {
              width: 38px;
            }
            &:first-child {
              position: relative;
              // width: 50%;
              width: calc(100% - 50px);
              padding-right: 0px;
              text-align: left;
              &::after {
                position: absolute;
                content: "";
                width: 1px;
                height: 24px;
                background: rgba(0, 0, 0, 0.08);
                right: 10px;
                top: 10px;
                display: none;
              }
            }
            &:last-child {
              padding-left: 10px;
            }
          }
        }
        // video {
        //    width: 100%;
        //    height: 533px;
        //    border: none;
        // }

        video {
          height: auto;
          width: 100%;
          // aspect-ratio: 9 / 16;
          // aspect-ratio: 1.17 / 1;
          border: 0;
          border-radius: 11px;
        }

        // iframe {
        //    height: auto;
        //    width: 100%;
        //    // aspect-ratio: 9 / 16;
        //    aspect-ratio: 1.17 / 1;
        //    border: 0;
        //    border-radius: 11px;
        // }
      }
      .cardBlue {
        background: $blue;
        border-radius: 18px;
        padding: 8px 20px 20px;
        margin-bottom: 5px;
        h2 {
          margin: 10px 0;
          font-family: $worksans-medium;
        }
        figure {
          width: 100%;
          // max-height: 221.66px;
          // min-height: 221.66px;
          // overflow: hidden;
          border-radius: 11px;
          margin-bottom: 20px;
          img {
            max-width: 100%;
            // max-height: 221.66px;
            // min-height: 221.66px;
            border-radius: 11px;
            object-fit: fill;
          }
        }
        p {
          color: $text-black;
          opacity: 80%;
          line-height: 16.42px;
        }
        .content {
          display: flex;
          justify-content: space-between;
          padding: 5px 0px 5px;
          width: 100%;
          .checkedinDate {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 50%;

            &:first-child {
              position: relative;
              width: 50%;
              padding-right: 30px;
              &::after {
                position: absolute;
                content: "";
                width: 1px;
                height: 24px;
                background: rgba(0, 0, 0, 0.08);
                right: 10px;
                top: 10px;
              }
            }
            &:last-child {
              padding-left: 30px;
            }
            span.dataLabel {
              white-space: break-spaces;
              word-wrap: break-word;
            }
          }
        }
      }
      .cardYellow {
        background: $yellow;
        border-radius: 18px;
        padding: 8px 20px 20px;
        margin-bottom: 5px;
        h2 {
          margin: 10px 0;
          font-family: $worksans-medium;
        }
        p {
          color: $text-black;
          opacity: 80%;
          line-height: 16.42px;
        }

        button {
          text-transform: lowercase;
          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
      .cardGreen2 {
        background: $green;
        border-radius: 18px;
        padding: 8px 20px 20px;
        margin-bottom: 5px;
        h2 {
          margin: 10px 0;
          font-family: $worksans-medium;
        }
        p {
          color: $text-black;
          opacity: 80%;
          line-height: 16.42px;
        }
        .localMap {
          background: $white;
          padding: 10px;
          border-radius: 18px;

          figure {
            width: 100%;
            max-height: 145px;
            min-height: 145px;
            overflow: hidden;
            border-radius: 6px;
            margin-bottom: 0;
            img {
              width: 100%;
              max-height: 145px;
              min-height: 145px;
              border-radius: 6px;
              object-fit: cover;
            }
          }
          .content {
            display: flex;
            justify-content: space-between;
            padding: 15px 0px 5px;
            align-items: center;
            flex-direction: column;
            .dataLabel {
              font-weight: 700;
            }
            .card-subtitle {
              font-size: $font-size-12;
              color: $text-para;
              font-family: $worksans-regular;
              display: block;
              width: 100%;
            }
            button {
              div {
                text-transform: lowercase;
                &::first-letter {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
      }
      .cardPink{
         background: $pink;
       }
      .cardOrange {
        background: $orange;
        border-radius: 18px;
        padding: 8px 20px 20px;
        margin-bottom: 5px;
        h2 {
          margin: 10px 0;
          font-family: $worksans-medium;
        }
        p {
          color: $text-black;
          opacity: 80%;
          line-height: 16.42px;
        }

        button {
          text-transform: lowercase;
          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
      .cardBlank {
        background: none;
        padding: 20px 15px 40px;
        margin-bottom: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
          margin: 10px 0 25px;
          color: $text-white;
          font-family: $worksans-medium;
          text-align: center;
        }
        figure {
          background: #fff;
          border: 2px solid #fff;
          border-radius: 50px;
          margin-bottom: 5px;
          max-height: 48px;
          min-height: 48px;
          overflow: hidden;
          width: 48px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            height: 100%;
            object-fit: fill;
            padding: 0;
            width: 100%;
          }
        }

        button.outlinebutton {
          border: 2px solid $white !important;
          color: $text-white !important;
          @include outlinebutton;
          &:hover,
          &:focus,
          &:active {
            border: 2px solid $white !important;
            color: $text-white !important;
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .checkinginpage {
    height: auto;
    padding: 0 10px 10px;
    // background: $innerbg;

    .card-section {
      width: auto;
      .accrodianBlock {
        .cardYellow {
          background: $yellow;
          border-radius: 18px;
          padding: 15px 0 17px;
          margin-bottom: 5px;
          border: none;

          .accordion-header {
            display: inline-flex;
            padding-top: 3px;
            align-items: baseline;

            button {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              display: flex;
              align-items: baseline;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: 2px;
              }
            }
            button.collapsed {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              display: flex;
              align-items: baseline;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: 0px;
                position: relative;
                left: 0;
                top: 1px;
              }
            }
            h2 {
              margin-top: 0px;
              font-family: $worksans-medium;
              letter-spacing: -0.2px;
              display: flex;
              align-items: center;
              span {
                margin-left: 5px;
              }
            }
          }
          .accordion-body {
            padding-top: 15px;
            padding-bottom: 7px;
            .swiperSliderone {
              .swiper {
                height: 210px;
                .swiper-slide {
                  .form-check {
                    position: relative;
                    .form-check-input {
                      width: 100%;
                      height: 180px;
                      background: none;
                      // border-radius: 14px;
                      box-shadow: none;
                      border: none;
                      margin-top: 0;
                      &:hover,
                      &:focus,
                      &:active {
                        box-shadow: none;
                        border: none;
                        background: none;
                      }

                      &:checked[type="checkbox"] {
                        background: none;
                        &:hover,
                        &:focus,
                        &:active {
                          box-shadow: none;
                          border: none;
                          background: none;
                        }
                      }
                    }

                    .form-check-input:checked[type="checkbox"] + label {
                      position: absolute;
                      top: 0;
                      font-size: $font-size-18;
                      font-family: $worksans-medium;
                      font-weight: 500;
                      color: $text-black;
                      left: 0;
                      width: 100%;
                      height: 180px;
                      border-radius: 14px;
                      background: rgba(255, 255, 255, 1);
                      padding: 65px 14px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      line-height: 21px;
                      opacity: 1;
                    }
                    .form-check-input[type="checkbox"] + label {
                      position: absolute;
                      top: 0;
                      font-size: $font-size-18;
                      font-family: $worksans-medium;
                      font-weight: 500;
                      left: 0;
                      width: 100%;
                      height: 180px;
                      border-radius: 14px;
                      background: rgba(30, 28, 34, 0.1);
                      padding: 65px 14px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      line-height: 21px;
                      opacity: 0.5;
                    }
                    label {
                      color: $text-label-checkbox;
                    }
                  }
                }
                .swiper-slide.swiper-slide-active {
                  .form-check {
                    position: relative;
                    .form-check-input {
                      width: 100%;
                      height: 180px;
                      background: none;
                      // border-radius: 14px;
                      box-shadow: none;
                      border: none;
                      margin-top: 0;
                      &:hover,
                      &:focus,
                      &:active {
                        box-shadow: none;
                        border: none;
                        background: none;
                      }

                      &:checked[type="checkbox"] {
                        background: none;
                        &:hover,
                        &:focus,
                        &:active {
                          box-shadow: none;
                          border: none;
                          background: none;
                        }
                      }
                    }

                    .form-check-input:checked[type="checkbox"] + label {
                      position: absolute;
                      top: 0;
                      font-size: $font-size-18;
                      font-family: $worksans-medium;
                      font-weight: 500;
                      color: $text-black;
                      left: 0;
                      width: 100%;
                      height: 180px;
                      border-radius: 14px;
                      background: rgba(255, 255, 255, 1);
                      padding: 65px 14px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      line-height: 21px;
                      letter-spacing: -0.2px;
                      opacity: 1;
                    }
                    .form-check-input[type="checkbox"] + label {
                      position: absolute;
                      top: 0;
                      font-size: $font-size-18;
                      font-family: $worksans-medium;
                      font-weight: 500;
                      left: 0;
                      width: 100%;
                      height: 180px;
                      border-radius: 14px;
                      background: rgba(30, 28, 34, 0.1);
                      padding: 65px 14px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      line-height: 21px;
                      color: $text-black;
                      letter-spacing: -0.2px;
                      opacity: 1;
                    }
                  }
                }
                .swiper-pagination {
                  bottom: 0;
                  text-align: left;
                  font-family: $worksans-semibold;
                  font-weight: 500;
                  font-size: $font-size-12;
                  color: $text-black;
                  opacity: 50%;
                  letter-spacing: -1.3px;
                }

                .swiper-slide.autowidthchange {
                  width: auto !important;

                  .id_card_block.image-uploaded {
                    position: relative;
                    overflow: hidden;
                    display: inline-block;
                    width: auto;
                    height: 133px;
                    .id_card_uploaded {
                      width: auto;
                      figure {
                        width: auto;
                        img {
                          width: auto;
                        }
                      }
                    }
                  }
                  .id_card_block_selfie.image-uploaded {
                    position: relative;
                    overflow: hidden;
                    display: inline-block;
                    width: auto;
                    height: 133px;
                    .id_card_uploaded {
                      width: auto;
                    }
                  }
                }
              }
            }
            .swiperSliderone .swiper .swiper-slide-active {
              .id_card_uploaded {
                background: rgba(30, 28, 34, 0.1);
                border: none;
              }
            }

            button {
              div {
                text-transform: lowercase;
                &::first-letter {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
        .cardOrange {
          background: $orange;
          border-radius: 18px;
          padding: 15px 0 17px;
          margin-bottom: 5px;
          border: none;

          .accordion-header {
            display: inline-flex;
            padding-top: 2px;
            align-items: baseline;
            button {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              display: flex;
              align-items: baseline;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: 0px;
                position: relative;
                left: 0;
                top: 1px;
              }
            }
            button.collapsed {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              display: flex;
              align-items: baseline;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: 0px;
                position: relative;
                left: 0;
                top: 1px;
              }
            }
            h2 {
              margin-top: 0px;
              font-family: $worksans-medium;
              letter-spacing: -0.2px;
              display: flex;
              align-items: center;
              span {
                margin-left: 5px;
              }
            }
          }
          .accordion-body {
            padding-top: 15px;
            padding-bottom: 7px;
            .readagreement {
              max-height: 233px;
              overflow-y: auto;
              margin-bottom: 20px;
              padding-right: 10px;
              p {
                color: $text-black;
                opacity: 60%;
              }
            }
            button {
              div {
                text-transform: lowercase;
                &::first-letter {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
        .cardBlue {
          background: $blue;
          border-radius: 18px;
          padding: 15px 0 17px;
          margin-bottom: 5px;
          border: none;

          .accordion-header {
            display: inline-flex;
            padding-top: 1px;
            align-items: baseline;
            button {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              display: flex;
              align-items: baseline;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: 0px;
                position: relative;
                left: 0;
                top: 1px;
              }
            }
            button.collapsed {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              display: flex;
              align-items: baseline;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: 0px;
                position: relative;
                left: 0;
                top: 1px;
              }
            }
            h2 {
              margin-top: 0px;
              font-family: $worksans-medium;
              letter-spacing: -0.2px;
              display: flex;
              align-items: center;
              span {
                margin-left: 5px;
              }
            }
          }
          .accordion-body {
            padding-top: 15px;
            padding-bottom: 7px;
            h5 {
              margin-bottom: 3px;
            }
            .sub-title {
              font-family: $worksans-regular;
              font-weight: 400;
              color: $text-black;
              font-size: $font-size-13;
              display: block;
              opacity: 60%;
              line-height: 15.76px;
            }
            .sub-title-2 {
              font-family: $worksans-regular;
              font-weight: 400;
              color: $text-black;
              font-size: $font-size-13;
              display: block;
              opacity: 70%;
              line-height: 15.76px;
            }

            .swiperSliderone {
              .swiper {
                height: 160px;

                // .id_screen_upd {
                //    position: relative;
                //    overflow: hidden;
                //    display: inline-block;
                //    width: 100%;
                //    height: 133px;
                //  }

                //  .btn {
                //    border: 1px dashed rgba(0, 0, 0, 0.3);
                //    background-color: none;
                //    padding: 8px 20px;
                //    border-radius: 10px;
                //    font-size: 20px;
                //    font-weight: bold;
                //    width: 100%;
                //    height: 133px;
                //    p {
                //       @include paragraph;
                //       text-transform: lowercase;
                //          &::first-letter {
                //             text-transform: uppercase;
                //          }

                //       span {
                //          text-transform: uppercase;
                //       }
                //    }
                //  }

                //  .id_screen_upd input[type=file] {
                //    font-size: 100px;
                //    position: absolute;
                //    left: 0;
                //    top: 0;
                //    opacity: 0;
                //    width: 100%;
                //  }

                //  .swiper-slide.swiper-slide-active {

                //    .btn {
                //       border: none;
                //       background-color: rgba(30, 28, 34, 0.1);
                //       padding: 8px 20px;
                //       border-radius: 10px;
                //       font-size: 20px;
                //       font-weight: bold;
                //       width: 100%;
                //       height: 133px;
                //       p {
                //          @include paragraph;
                //          text-transform: lowercase;
                //             &::first-letter {
                //                text-transform: uppercase;
                //             }

                //          span {
                //             text-transform: uppercase;
                //          }
                //       }
                //     }

                //  }

                .id_card_block {
                  position: relative;
                  overflow: hidden;
                  display: inline-block;
                  width: 100%;
                  height: 133px;
                }

                .id_card_uploaded {
                  border: 1px dashed rgba(0, 0, 0, 0.3);
                  background: none;
                  padding: 0;
                  border-radius: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  width: 100%;
                  height: 133px;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: center;
                  text-align: center;
                  overflow: hidden;
                  figure {
                    height: 133px;
                    width: 100%;
                    margin: 0;
                    position: relative;

                    img {
                      height: 133px;
                      width: 100%;
                      object-fit: contain;
                    }

                    span.deletePreview {
                      position: absolute;
                      top: 1px;
                      right: 5px;
                      padding: 0;
                      background: none;
                      cursor: pointer;
                      img {
                        width: 28px;
                        height: 28px;
                      }
                    }
                  }

                  p.id_label {
                    font-size: $font-size-16 !important;
                    color: $text-para-2 !important;
                    margin-top: 5px;
                    @include paragraph;

                    text-transform: lowercase;
                    &::first-letter {
                      text-transform: uppercase;
                    }

                    span {
                      text-transform: uppercase;
                    }
                  }
                }

                .id_card_block_selfie {
                  position: relative;
                  overflow: hidden;
                  display: inline-block;
                  width: 100px;
                  height: 133px;
                }

                .id_card_uploaded_selfie {
                  border: 1px dashed rgba(0, 0, 0, 0.3);
                  background: none;
                  padding: 0;
                  border-radius: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  width: 100px;
                  height: 133px;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: center;
                  text-align: center;
                  overflow: hidden;
                  figure {
                    height: 133px;
                    width: 100%;
                    margin: 0;
                    position: relative;

                    img {
                      height: 133px;
                      width: 100%;
                      object-fit: contain;
                    }

                    span.deletePreview {
                      position: absolute;
                      top: 1px;
                      right: 5px;
                      padding: 0;
                      background: none;
                      cursor: pointer;
                      img {
                        width: 28px;
                        height: 28px;
                      }
                    }
                  }

                  p.id_label {
                    font-size: $font-size-16 !important;
                    color: $text-para-2 !important;
                    margin-top: 5px;
                    @include paragraph;

                    text-transform: lowercase;
                    &::first-letter {
                      text-transform: uppercase;
                    }

                    span {
                      text-transform: uppercase;
                    }
                  }
                }

                .id_card {
                  border: 1px dashed rgba(0, 0, 0, 0.3);
                  background: none;
                  padding: 8px 20px;
                  border-radius: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  width: 100%;
                  height: 133px;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: center;
                  text-align: center;

                  img {
                    width: 32px;
                  }

                  p.id_label {
                    font-size: $font-size-16 !important;
                    color: $text-para-2 !important;
                    margin-top: 5px;
                    @include paragraph;

                    text-transform: lowercase;
                    &::first-letter {
                      text-transform: uppercase;
                    }

                    span {
                      text-transform: uppercase;
                    }
                  }
                }

                .id_card_selfie {
                  border: 1px dashed rgba(0, 0, 0, 0.3);
                  background: none;
                  padding: 15px 20px 0;
                  border-radius: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  width: 100px;
                  height: 133px;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: center;
                  text-align: center;

                  img {
                    width: 32px;
                  }
                }

                .swiper-slide.swiper-slide-active {
                  .id_card {
                    border: none;
                    background-color: rgba(30, 28, 34, 0.1);
                    padding: 8px 20px;
                    border-radius: 10px;
                    font-size: 20px;
                    font-weight: bold;
                    width: 100%;
                    height: 133px;
                    img {
                      width: 32px;
                    }
                    p {
                      @include paragraph;
                      text-transform: lowercase;
                      &::first-letter {
                        text-transform: uppercase;
                      }

                      span {
                        text-transform: uppercase;
                      }
                    }
                  }

                  .id_card_selfie {
                    border: none;
                    background-color: rgba(30, 28, 34, 0.1);
                    padding: 15px 20px 0;
                    border-radius: 10px;
                    font-size: 20px;
                    font-weight: bold;
                    width: 100px;
                    height: 133px;
                    img {
                      width: 32px;
                    }
                    p {
                      @include paragraph;
                      text-transform: lowercase;
                      &::first-letter {
                        text-transform: uppercase;
                      }

                      span {
                        text-transform: uppercase;
                      }
                    }
                  }
                }

                .swiper-pagination {
                  bottom: 0;
                  text-align: left;
                  font-family: $worksans-semibold;
                  font-weight: 500;
                  font-size: $font-size-12;
                  color: $text-black;
                  opacity: 50%;
                  letter-spacing: -1.3px;
                }

                .swiper-slide.autowidthchange {
                  width: auto !important;

                  .id_card_block.image-uploaded {
                    position: relative;
                    overflow: hidden;
                    display: inline-block;
                    width: auto;
                    height: 133px;
                    .id_card_uploaded {
                      width: auto;
                      figure {
                        width: auto;
                        img {
                          width: auto;
                        }
                      }
                    }
                  }
                  .id_card_block_selfie.image-uploaded {
                    position: relative;
                    overflow: hidden;
                    display: inline-block;
                    width: auto;
                    height: 133px;
                    .id_card_uploaded {
                      width: auto;
                      figure {
                        width: auto;
                        img {
                          width: auto;
                        }
                      }
                    }
                  }
                }
              }
            }
            .swiperSliderone .swiper .swiper-slide-active {
              .id_card_uploaded {
                background: rgba(30, 28, 34, 0.1);
                border: none;
              }
            }

            button {
              div {
                text-transform: lowercase;
                &::first-letter {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
        .cardGreen {
          background: $green;
          border-radius: 18px;
          padding: 15px 0 17px;
          margin-bottom: 5px;
          border: none;

          .accordion-header {
            display: inline-flex;
            padding-top: 2px;
            align-items: baseline;
            button {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              display: flex;
              align-items: baseline;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: 0px;
                position: relative;
                left: 0;
                top: 1px;
              }
            }
            button.collapsed {
              background: transparent;
              box-shadow: none;
              padding-bottom: 0;
              padding-top: 0;
              display: flex;
              align-items: baseline;
              &::after {
                display: none;
              }

              img {
                margin-right: 7px;
                margin-top: 0px;
                position: relative;
                left: 0;
                top: 1px;
              }
            }
            h2 {
              margin-top: 0px;
              font-family: $worksans-medium;
              letter-spacing: -0.2px;
              display: flex;
              align-items: center;
              span {
                margin-left: 5px;
              }
            }
          }
          .accordion-body {
            padding-top: 15px;
            padding-bottom: 7px;
            .radiobox {
              .form-check-input:checked[type="radio"] {
                border: 6px solid $black;
                background: none;
              }
              .form-check-input[type="radio"] {
                border: 1px solid rgba(0, 0, 0, 0.3);
                background: none;

                &:focus,
                &:hover,
                &:active {
                  box-shadow: 0 0 0 0.25rem rgb(93 93 93 / 25%) !important;
                }
              }
              label {
                font-family: $worksans-medium;
                font-size: $font-size-16;
                color: $text-black;
                font-weight: 500;
                opacity: 1;
              }
            }

            button {
              div {
                text-transform: lowercase;
                &::first-letter {
                  text-transform: uppercase;
                }
              }
            }

            ul {
              padding-left: 15px;
              margin-bottom: 60px;
              li {
                @include paragraph2;
                margin-bottom: 7px;
                opacity: 70% !important;
                &::marker {
                  position: absolute;
                  font-size: 95%;
                }
              }
            }
          }
        }

        .accrodionCollapsed {
          pointer-events: none;
          button.collapsed {
            background: transparent;
            box-shadow: none;
            padding-bottom: 0;
            padding-top: 0;
            display: flex;
            align-items: baseline;
            &::after {
              display: none;
            }

            img {
              margin-right: 7px !important;
              margin-top: 0px !important;
              width: 17px;
            }
          }
        }
      }
    }
  }
}

.p-datepicker-calendar-container {
  .p-disabled {
    opacity: 0.2;
  }
}

.early_checkin_button {
  display: inline-block;
  position: absolute;
  z-index: 1;
  top: 70px;
  left: 5px;
  button {
    border-radius: 50px;
    background-color: #ffed49;
    border: 2px solid#FFED49;
    outline: none;
    font-weight: 700;
    color: #000;
    padding: 3px 25px 3px 8px;
    img {
      margin-right: 8px;
      width: 24px;
      height: 24px;
    }
    &:hover,
    &:focus-visible,
    &:focus {
      background-color: #ffed49;
      outline: none;
      border-color: #ffed49;
      color: #000;
      box-shadow: none;
    }
    &:active {
      background-color: #ffed49 !important;
      border-color: #ffed49 !important;
      color: #000 !important;
    }
  }
}

span {
  &.edittor_text_common_style {
    color: #000;
    p {
      color: #000 !important;
      margin-bottom: 5px;
      word-break: break-word;
      font-size: 15px;
      min-height: 12px;
      span {
        color: #000 !important;
      }
      strong {
        color: #000 !important;
      }
      img {
        width: 100%;
      }
    }
    h1,h2,h3,h4.h5,h6 {
      color:$text-black;
      padding: 0;
      text-align: left;
    }
    iframe {
      width: 100%;
      height: 30vh;
    }
  }
}

// span{
//    &.edittor_text_common_style{
//       color: #000;
//       p{
//          color: #000 !important;
//          span{
//             color: #000 !important;
//          }
//          strong{
//             color: #000 !important;
//          }
//       }
//    }
// }

.custom_select_drop_box {
  .Dropdown-root {
    .Dropdown-control {
      padding: 12px 52px 8px 10px;
      border-radius: 12px;
      border: 2px solid rgba(0, 0, 0, 0.08);
      background: rgba(0, 0, 0, 0.04);
      font-family: Work Sans;
      .Dropdown-arrow {
        top: 18px;
      }
    }
  }
  .Dropdown-menu {
    background: #000;
    color: #fff;
    border-radius: 0 0 6px 6px;
    bottom: 100%;
    top: auto;
    max-height: 285px;

    .Dropdown-option {
      color: #fff;

      &.is-selected {
        background: #000;
        color: #e5d642;
      }
      &:hover {
        background: #454343;
        color: #e5d642;
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #000;
    }
    &::-webkit-scrollbar-thumb {
      background: #ffc062;
      border-radius: 6px;
    }
  }
}

@media (max-width: 767px) {
  .mainpage {
    &.cleaning_section {
      .content {
        button {
          line-height: 42px;
        }
      }
    }
  }
}

.mainpage {
  .content {
    .request_invite_btn {
      display: inline-block;
      text-decoration: none;
      height: 56px;
      button {
        border: none;
        letter-spacing: -0.7px;
        height: 56px;
        border-radius: 50px;
        line-height: 1.5;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 12px 0px;
        &:hover {
          border: none !important;
        }
      }
      // @media(max-width:1024px){
      //    button{
      //       padding-top: 8px;
      //    }
      // }
    }
    button {
      &.checkout_day_btn {
        border: none;
        letter-spacing: -0.7px;
        height: 56px;
        border-radius: 50px;
        line-height: 1.5;
        padding: 2px 12px 0px;
        display: inline-block;
        @media (max-width: 767px) {
          width: auto;
          padding: 2px 20px 0px;
        }
      }
    }
  }
}

.modal {
  &.earlycheckin {
    .modal-dialog {
      .modal-content {
        .modal-body {
          h6 {
            &.early_checkin_popup_heading {
              opacity: 80%;
              font-size: 14px;
              a{
                color: $text-black;
                word-break: break-all;
              }
            }
            &:nth-of-type(2){
              margin-bottom: 0;
            }
          }
          button {
            &.outlinebutton {
              &.cancel_btn {
                margin-right: 10px;
              }
            }
          }
        }
      }
   }
  }
   &.message-chat-modal{
    &.fade{
      .modal-dialog{
        transition: none;
        transform: translateX(-50%) !important;
      }
    }
    .modal-dialog{
      &.modal-dialog-animation {
        transform: translate(-50%,-5px) !important; // Start position
        transition: transform 1s ease-in-out; // Smooth transition for both properties
      }
      .modal-header{
        border-bottom: none;
        padding: 0;
        position: relative;
        .btn-close{
          position: absolute;
          right: 10px;
          top: -28px;
          width: 62px;
          background: rgb(255, 248, 239);
          border-radius: 50px;
          opacity: unset;
          font-size: 13px;
          border: none;
          outline: none;
          font-family: WorkSans-Bold;
          padding: 10px 0px 9px;
          box-shadow: none;
          --bs-btn-close-bg: none;
          line-height: 13px;
          &.expandedCloseBtn{
            top: 32px;
          }
        }
        .expend-icon{
          width: 25px;
          height: 6px;
          background: rgba(0, 0, 0, 0.1);
          border-radius: 33px;
          position: absolute;
          top: 5px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1;
          cursor: pointer;
        }
        .expend-body-title{
          display: none;
          &.expandedHeader{
            padding: 16px;
            width: 100%;
            text-align: center;
            padding: 28px 16px 0;
            display: block;
          h2{
            font-size: $font-size-20;
            font-family: $worksans-semibold;
          }
          }
        }
        .headerBox{
          display: flex;
          justify-content: center;
          width: 100%;
        }
      }
      transform: translateX(-50%) !important;
      transition: none;
      .modal-content{
        background: #FFF8EF;
        border-radius: 18px 18px 0 0;
      }
      &.chat-custom-modal {
        position: fixed;
        bottom: 0;
        width: 100%;
        margin: 0;
        border-radius: 12px 12px 0 0; 
        z-index: 1050; 
        // overflow: hidden;
        max-height: 90vh; 
        max-width: 414px;
        transition: transform 0.5s ease-in-out;
        margin-bottom: -4px;

        &.modal-dialog-animation{
          margin-bottom: -4px;
        }

        .modal-body {
          max-height: 28vh;
          overflow-y: auto;
          padding-top: 0;
          .message-reload-box{
            display: none;
          }
          .message-chat-body{
            background: rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            margin-top: 20px;
            overflow-y: hidden;
            min-height: 100px;
            max-height: 100px;
            .message-chat-avatar{
              width: 65px;
            }
                // padding: 16px 5px 16px 20px;
                // .chat-card{
                //   display: flex;
                //   align-items: flex-start;
                //   position: relative;
                //   overflow: hidden;
                //   margin-bottom: 15px;
                //   .image-container {
                //     width: 50px;
                //     min-width: 50px;
                //     height: 50px;
                //     border-radius: 50%;
                //     background-color: #fff;
                //     display: flex;
                //     justify-content: center;
                //     align-items: center;
                //     margin-right: 10px;
                //     img {
                //     width: 58px;
                //     height: auto;
                //     border-radius: 50%;
                //     }
                //   }
                //   .text-container{
                //     opacity: 0;
                //     transition: opacity 1s ease-in-out;
                //     position: absolute;
                //     left: 70px;
                //     animation: fadeOut 4s forwards;
                //     h6{
                //       margin-bottom: 3px;
                //       font-size: $font-size-12;
                //       color: $black;
                //       font-family: $worksans-medium;
                //     }
                //   }
                //   .text-container-1{
                //     opacity: 0;
                //     transition: opacity 1s ease-in-out;
                //     animation: fadeIn 4s forwards;
                //     h6{
                //       margin-bottom: 3px;
                //       font-size: $font-size-12;
                //       color: $black;
                //       font-family: $worksans-medium;
                //       span{
                //         color: rgba(0,0,0,0.48);
                //         font-size: 10px;
                //         font-family: $worksans-regular;
                //         margin-left: 5px;
                //       }
                //     }
                //     p{
                //       padding: 15px;
                //       border-radius: 12px;
                //       background-color: rgba(0, 0, 0, 0.04);
                //       margin-bottom: 0;
                //       font-size: $font-size-13;
                //     }
                //   }
                //   &:last-child{
                //     margin-bottom: 0;
                //   }
                // }
                .sender-message-box {
                  .inner-chatbox{
                    padding: 10px 5px 16px 20px;
                    .wrap-text-message{
                      max-height: 49px; /* Adjust this based on your line height */
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 2; /* Limit to 2 lines */
                      -webkit-box-orient: vertical;
                    }
                    .date-text{
                      font-size: $font-size-12;
                      color: $black;
                      font-family: $worksans-medium;
                      margin-top: 16px;
                      margin-bottom: 12px;
                      opacity: 48%;
                      display: none;
                    }
                    .guest-send-box{
                      display: flex;
                      justify-content: flex-end;
                      margin-top: 0px;
                      img{
                        width: 58px;
                        height: 58px;
                        object-fit: cover;
                        border-radius: 100%;
                        margin-right: 10px;
                      }
                      .guest-content-box {
                        .guest-text-message{
                          padding: 8px;
                          border-radius: 8px;
                          background-color: rgba(37, 33, 30, 1);
                          white-space: pre-wrap;
                          p{
                            color: $text-white;
                            margin-bottom: 0;
                            opacity: 1;
                            font-size: $font-size-13;
                            line-height: normal;
                            word-break: break-word;
                          }
                        }
                        .time-text-box {
                          text-align: right;
                          span{
                            &:first-child{
                              font-size: $font-size-12;
                              color: $black;
                              font-family: $worksans-medium;
                            }
                            &:last-child{
                              color: rgba(0,0,0,0.48);
                              font-size: 10px;
                              font-family: $worksans-regular;
                              margin-left: 5px;
                            }
                          }
                        }
                      }
                    }
                    .host-message-box{
                      display: flex;
                      img{
                        width: 50px;
                        min-width: 50px;
                        height: 50px;
                        object-fit: cover;
                        border-radius: 100%;
                        margin-right: 10px;
                      }
                      .host-content-box {
                        .time-text-box {
                          line-height: 10px;
                          margin-bottom: 5px;
                          span{
                            &:first-child{
                              font-size: $font-size-12;
                              color: $black;
                              font-family: $worksans-medium;
                            }
                            &:last-child{
                              color: rgba(0,0,0,0.48);
                              font-size: 10px;
                              font-family: $worksans-regular;
                              margin-left: 5px;
                            }
                            &.default-message{
                              font-family: $worksans-medium;
                              font-size: $font-size-12;
                              color: $black;
                            }
                          }
                        }
                        .host-text-message{
                          padding: 8px;
                          border-radius: 8px;
                          background-color: rgba(0, 0, 0, 0.04);
                          margin-bottom: 0;
                          white-space: pre-wrap;
                          p{
                            margin-bottom: 0;
                            font-size: $font-size-13;
                            line-height: normal;
                            opacity: unset;
                            word-break: break-word;
                          }
                        }
                      }
                    }
                  }
                }
          &::-webkit-scrollbar {
            width:8px;
          }
          &::-webkit-scrollbar-track {
            background: transparent;
          }
          &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.1);
            border-radius: 6px;
          }
          }
          &.expend-chat-body{
            max-height: 80vh;
            transition: transform 0.5s ease-in-out;
            .message-chat-body{
              overflow-y: auto;
              min-height: 63vh;
              max-height: 63vh;
              .sender-message-box {
                .inner-chatbox{
                  padding: 16px 5px 16px 20px;;
                  .guest-send-box{
                    margin-top: 10px;
                  }
                  .date-text{
                    display: block;
                  }
                  .host-message-box {
                    margin-top: 10px;
                  }
                  .mt-20{
                    margin-top: -20px;
                  }
                }
              }
            }
            .message-reload-box{
              position: absolute;
              top: 0px;
              left: 50%;
              line-height: 10px;
              transform: translateX(-50%);
              display: block;
              .typing-indicator{
                background-color: unset;
                padding: 0;
                margin: 0;
              }
            }
          }
        }
        @media(max-width: 576px){
          max-width: 576px;
        }
      }
    }
    .outlinebutton{
      border-radius: 50%;
      background-color: #25211E;
      border: 2px solid #25211E;
      outline: none;
      width: 40px;
      min-width: 40px;
      height: 40px;
      color: #fff;
      padding: 10px;
      text-align: center;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    .send-message-input-box{
      transform: translate(0%,0px) !important; // Start position
      transition: transform 0.5s ease-in-out;
      &.send-message-animation{
        transform: translate(0%,-2px) !important; // Start position
        transition: transform 0.5s ease-in-out;
      }
      width: 100%;
      margin-top: 15px;
      // transform: translate(0%,-10px) !important;
      // transition: transform 1s ease-in-out;
      .inner-box{
        display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 11px;
          input{
            margin-right: 15px;
            box-shadow: none;
            resize: none;
            background: rgba(0, 0, 0, 0.04);
            border: 2px solid rgba(0, 0, 0, 0.08);
            font-size: $font-size-13;
            color: rgba(0,0,0,0.6);
            font-family: $worksans-medium;
            border-radius: 12px;
            height: 46px;
          }
      }
    }
   }
}

.Toastify{
  @media(max-width: 767px){
    .Toastify__toast-container{
      left: auto;
      right: 6px;
      top: 4px;
      width: auto;
      margin-left: 10px;
      
      .Toastify__toast{
        border-radius: 10px;
      }
    }
  }
}

@media (min-width: 1023px) and (max-width: 1024px){
  .modal{
    &.message-chat-modal {
      .modal-dialog{
        &.chat-custom-modal{
          max-width: 510px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .chat-custom-modal {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-height: 685px) {
  .modal{
    &.message-chat-modal {
      .modal-dialog{
        &.chat-custom-modal {
          .modal-body{
            max-height: 34vh;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  // .chat-custom-modal {
  //   left: 0%;
  //   bottom: 0;
  //   margin-bottom: -4px;
  // }
  .modal{
    &.message-chat-modal {
      &.fade{
        .modal-dialog{
          transition: none;
          transform: translateX(0%) !important;
        }
      }
      .modal-dialog{
        &.modal-dialog-animation {
          transform: translate(0%,-7px) !important; // Start position
          transition: transform 1s ease-in-out; // Smooth transition for both properties
        }
        &.chat-custom-modal {
          max-height: 86vh;
          left: 0%;
          bottom: 0;
          transition: transform 0.5s ease-in-out;
          margin-bottom: -8px;

          &.modal-dialog-animation{
            margin-bottom: -9px;
          }
          .modal-body{
            &.expend-chat-body{
              &.open-keyboard{
                max-height: 44vh;
                .message-chat-body{
                  min-height: 28vh;
                  max-height: 28vh;
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes bounceIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
  }
  70% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes bounceOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(0.3);
    opacity: 0;
  }
}

body.modal-open.open-host-message-body{
  overflow: auto !important;
}
